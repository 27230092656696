import React, { useContext, useEffect, useState } from "react";
import DraftsCredentialTemplateCard from "../../components/Cards/DraftsCredentialTemplateCard/DraftsCredentialTemplateCard";
import { GET } from "../../service/CRUDService";
import EntityConstants from "../../configs/EntityConstants";
import AuthContext from "../../store/auth-context";

import ArrowLeftIcon from "../../resources/icons/arrow-narrow-left.svg";

import CfLoader from "../../common/CfLoader/CfLoader";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ViewAllDrafts = () => {
  const { maxLimit, currentStartDay, currentEndDay, timestamp } = useParams();

  const authContext = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [pageName, setPageName] = useState("");
  const [isLodingCredTemplates, setIsLoadingCredTemplates] = useState(false);
  const [offset, setOffset] = useState(0);
  const [prevOffset, setPrevOffset] = useState(0);
  const [limit, setlimit] = useState(8);
  const [startDay, setStartDay] = useState(currentStartDay);
  const [endDay, setEndDay] = useState(currentEndDay);
  const [count, setCount] = useState(0);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [recentList, setRecentList] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [pervList, setPervList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(true);

  const SEARCH = async (e) => {
    e.preventDefault();
    const searchTerm = e.target.value.trim();
    if (categoryName === "Recent") {
      setStartDay(0);
      setEndDay(7);
    } else if (categoryName === "Last 30 Days") {
      setStartDay(7);
      setEndDay(30);
    } else if (categoryName === "Last 3 Months") {
      setStartDay(30);
      setEndDay(90);
    } else if (categoryName === "Last 6 Months") {
      setStartDay(90);
      setEndDay(180);
    } else {
      setStartDay(180);
      setEndDay(365);
    }

    if (searchTerm.length > 2) {
      setSearchTerm(searchTerm);
      setIsSearching(true);
      setRecentList([]);
      setHasMoreResults(true);
      try {
        const response = await GET(
          `${EntityConstants.GET_DRAFTS_TEMPLATES}limit=${limit}&offset=${offset}&start_day=${startDay}&end_day=${endDay}&search=${searchTerm}`
        );
        if (response.ok) {
          setPervList([]);
          const data = await response.json();
          if (data.results) {
            setRecentList(data.results);
            setCount(data.count);
            setOffset(0);
            setIsSearched(true);
          } else {
            setHasMoreResults(false);
            setRecentList([]);
          }
        }
      } catch (error) {
        setIsSearching(false);
        console.error("Error during search:", error);
        setRecentList([]);
      } finally {
        setIsSearching(false);
      }
    } else {
      setRecentList([...pervList]);
      setIsSearching(false);
      setIsSearched(false);
    }
  };

  const GET_ISSUER_DRAFTS = (limit, offset, searchTerm = "") => {
    if (!hasMoreResults) return;
    setIsLoadingCredTemplates(true);
    const url = `${EntityConstants.GET_DRAFTS_TEMPLATES}limit=${limit}&offset=${offset}&start_day=${startDay}&end_day=${endDay}&search=${searchTerm}`;

    GET(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setNext(data.next);
          setPrevious(data.previous);
          setPrevOffset(offset - limit);
          setOffset(offset);
          setCount(data.count);
          if (data.results.length === 0) {
            setHasMoreResults(false);
          } else {
            setPervList([...pervList, ...data.results]);
          }
        }
      })
      .then(() => setIsLoadingCredTemplates(false))
      .catch((error) => {
        console.error(error);
      })
      .then(() => setIsLoadingCredTemplates(false));
  };

  const removeCredentialFromList = (credentialId) => {
    setRecentList((prev) =>
      prev.filter((item) => item.issuer_credential_template_id !== credentialId)
    );
  };

  useEffect(() => {
    if (pervList) {
      setRecentList([...pervList]);
    }
  }, [pervList]);

  useEffect(() => {
    const categoryFromPath = location.pathname
      .split("/")
      .pop()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    setCategoryName(categoryFromPath);
  }, [location]);

  useEffect(() => {
    if (
      authContext.hasDiscoveryReachedBottom &&
      recentList.length < count &&
      prevOffset !== offset + limit &&
      hasMoreResults
    ) {
      GET_ISSUER_DRAFTS(limit, offset + limit, searchTerm);
    }
  }, [authContext.hasDiscoveryReachedBottom, recentList]);

  useEffect(() => {
    timestamp && setPageName(timestamp.split("_").join(" "));
  }, [timestamp]);

  useEffect(() => {
    if (maxLimit > 0) {
      const MAX_LIMIT = parseInt(maxLimit);
      MAX_LIMIT && GET_ISSUER_DRAFTS(limit, offset);
      console.log("in :) --", maxLimit);
    }
  }, [maxLimit]);
  useEffect(() => {
    console.log("recentList.length", recentList.length);
  }, [recentList.length]);

  useEffect(() => {
    authContext.lastVisitedPathHandler(location.pathname);
  }, []);

  return (
    <div className="flex flex-col items-center w-full mt-20 bg-cfSecondary min-h-screen">
      <div
        className={`w-full bg-cfSearchContainer flex justify-center py-[0.8rem] px-4`}
      >
        <div
          className={`flex flex-col sm:flex-row items-start sm:items-center justify-between w-full`}
        >
          <div className="capitalize flex items-center gap-2 text-cfHeadingText font-semibold text-lg self-center md:self-start my-auto">
            <img
              src={ArrowLeftIcon}
              alt=""
              onClick={() => navigate(-1)}
              className="cursor-pointer"
            />
            <p>{pageName}</p>
          </div>
          {/* Search */}
          <form className=" bg-cfSecondary p-2 rounded-lg w-full sm:w-[25rem] self-center ml-[0.630rem] md:self-end md:m-0">
            <input
              type="text"
              name="search"
              id="search"
              onChange={(e) => SEARCH(e)}
              placeholder={`Search In ${categoryName}`}
              className="outline-none border-none p-0 placeholder:text-sm w-full"
              style={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}
            />
          </form>
        </div>
      </div>

      <div className="w-full p-4">
        <div className="flex items-center gap-6 flex-wrap w-full">
          {recentList.map(
            ({
              issuer_credential_template_id,
              credential_template_id,
              certificate_name,
              preview_image,
              created_at,
              updated_at,
              organisation,
              description,
              tagsList,
            }) => (
              <DraftsCredentialTemplateCard
                name={certificate_name}
                credential_template_id={credential_template_id}
                issuer_credential_template_id={issuer_credential_template_id}
                created_at={created_at}
                //! remove this when `updated_at` is added 'lastEdited={created_at?created_at:"14 Jan 2024"}'
                lastEdited={updated_at}
                thumbnail_image={preview_image}
                navigate_url={`/issuer/certificate/0/${credential_template_id}/${issuer_credential_template_id}/edit`}
                description={description}
                tagsList={tagsList}
                removeCredentialFromList={removeCredentialFromList}
              />
            )
          )}
          {isLodingCredTemplates && (
            <div className="w-full flex justify-center items-center">
              <CfLoader />
            </div>
          )}
          {isSearching && (
            <div className="w-full flex justify-center items-center">
              <CfLoader />
            </div>
          )}
          {isSearched && !isSearching && recentList.length === 0 && (
            <p className="text-grey-50 font-semibold text-lg mt-[1rem] ml-[2rem]">
              No results found
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewAllDrafts;
