import React from "react";

const ToolTip = ({
  component,
  message,
  tooltipPosition,
  bgColor,
  tooltipWidth,
}) => {
  return (
    <div className="group relative flex max-w-max flex-col items-center justify-center">
      {component}
      <div
        className={`absolute left-1/2 ${tooltipPosition} ml-auto mr-auto min-w-max -translate-x-1/2 scale-0 transform rounded-lg px-3 py-2 text-xs font-medium transition-all duration-500 group-hover:scale-100`}
      >
        <div className="flex flex-col items-center">
          <div
            className={`rounded p-2 text-center text-xs text-white whitespace-normal break-words ${tooltipWidth}`}
            style={{ backgroundColor: bgColor }}
          >
            {message}
          </div>
          <div
            className="clip-bottom h-0 w-0 border-l-4 border-r-4 border-t-4 border-l-transparent border-r-transparent"
            style={{ borderTopColor: bgColor }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ToolTip;
