import React, { useContext, useEffect, useState } from "react";
import { FileUpload } from "../../common/FileUpload/FileUpload";
import FormioSingleUpload from "../../common/FormioSingleUpload/FormioSingleUpload";
import ToggleButton from "../../common/ToggleButton/ToggleButton";

import ReactDOM from "react-dom";

import { HiSquare3Stack3D } from "react-icons/hi2";
import { BsFillFileEarmarkBinaryFill } from "react-icons/bs";
import { GET, POST } from "../../service/CRUDService";
import EntityConstants from "../../configs/EntityConstants";
import AuthContext from "../../store/auth-context";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { LuImageOff } from "react-icons/lu";
import { RxReload } from "react-icons/rx";
import CfLoader from "../../common/CfLoader/CfLoader";
import { imgRegex } from "../../configs/App.Regex";
import GenerateCertificate from "../../service/GenerateCertificate";
import CfBreadcrumb from "../../common/CfBreadcrumb/CfBreadcrumb";

const CertificateIssue = () => {
  const [isUploadMethod, setIsUploadMethod] = useState(false);

  const { credential_template_id, issuer_credential_template_id } = useParams();
  const [error, setError] = useState("");
  const [currentSVG, setCurrentSVG] = useState("");
  const [certificateData, setCertificateData] = useState({});
  const [fieldsChanged, setFieldsChanged] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useContext(AuthContext);

  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const [transactionName, setTransactionName] = useState("");
  const [customFieldChanged, setCustomFieldChanged] = useState({});
  const [isToggleOn, setIsToggleOn] = useState(false);

  const GET_CERTIFICATE_DATA = async (credential_template_id) => {
    GET(
      `${EntityConstants.GET_CREDENTIALS_SUBJECT_FORM}${credential_template_id}/subject?type=form`
    )
      .then((response) => response.json())
      .then((data) => {
        setCertificateData(data);
      })
      .catch((error) => {
        console.error("Error loading SVG:", error);
      });
  };
  const fetchDefaultSVG = async () => {
    try {
      const urlResp = await GET(
        `${EntityConstants.GET_USER_PREVIEW_SVG}?issuer_credential_template_id=${issuer_credential_template_id}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("The data is : ", data);
          setTransactionName(data.certificate_name);
          console.log(
            "setTransactionName(data.certificate_name) is : ",
            data.certificate_name
          );
          const { template_svg } = data;
          fetch(`${template_svg}`).then(async (response) => {
            if (response.ok) {
              const svgContent = await response.text();
              setCurrentSVG(svgContent);
              // setFieldsChanged(default_template_variables_data)
              //!injecting the svg content into the container
              const container = document.getElementById(
                "certificate_container"
              );
              container.style.filter =
                "drop-shadow(0 0 2px rgba(0, 0, 0, 0.264))";
              container.innerHTML = svgContent;
            } else if (response.status == 404) {
              const container = document.getElementById(
                "certificate_container"
              );
              ReactDOM.render(
                <div class="text-black flex justify-center w-full h-full text-lg">
                  <div className="flex flex-col items-center gap-2 w-fit h-fit">
                    <span className="flex items-center gap-2 w-fit h-fit  bg-gray-100 px-2 py-1 rounded-lg">
                      <p>Couldn't find image..</p>
                      <LuImageOff class="text-cfPrimaryCardHover " />
                    </span>
                    <span>
                      <p>Please create template again...</p>
                    </span>
                  </div>
                </div>,
                container
              );
            } else {
              const container = document.getElementById(
                "certificate_container"
              );
              ReactDOM.render(
                <div class="text-black flex justify-center w-full h-full text-lg">
                  <div
                    className="flex items-center gap-2 w-fit h-fit rounded-lg cursor-pointer"
                    onClick={() => {
                      ReactDOM.render(
                        <span className="w-full flex justify-center">
                          <CfLoader />
                        </span>,
                        container
                      );
                      fetchDefaultSVG();
                    }}
                  >
                    <p>Couldn't Load Image...</p>
                    <RxReload class="text-cfPrimaryCardHover " />
                  </div>
                </div>,
                container
              );
            }
          });
        });
      const urlContent = await urlResp.json();
      const { template_svg } = urlContent;
      // const newUrlContent = credential_template_id.replace('/const/www/credflow-media/const/www/', "/")
      const response = await fetch(`${template_svg}`);
      if (response.ok) {
        const svgContent = await response.text();
        setCurrentSVG(svgContent);
        // setFieldsChanged(default_template_variables_data)
        //!injecting the svg content into the container
        const container = document.getElementById("certificate_container");
        container.style.filter = "drop-shadow(0 0 2px rgba(0, 0, 0, 0.264))";
        container.innerHTML = svgContent;
      } else if (response.status == 404) {
        const container = document.getElementById("certificate_container");
        ReactDOM.render(
          <div class="text-black flex justify-center w-full h-full text-lg">
            <div className="flex flex-col items-center gap-2 w-fit h-fit">
              <span className="flex items-center gap-2 w-fit h-fit  bg-gray-100 px-2 py-1 rounded-lg">
                <p>Coulden't find image..</p>
                <LuImageOff class="text-cfPrimaryCardHover " />
              </span>
              <span>
                <p>Please create template again...</p>
              </span>
            </div>
          </div>,
          container
        );
      } else {
        const container = document.getElementById("certificate_container");
        ReactDOM.render(
          <div class="text-black flex justify-center w-full h-full text-lg">
            <div
              className="flex items-center gap-2 w-fit h-fit rounded-lg cursor-pointer"
              onClick={() => {
                ReactDOM.render(
                  <span className="w-full flex justify-center">
                    <CfLoader />
                  </span>,
                  container
                );
                fetchDefaultSVG();
              }}
            >
              <p>Couldn't Load Image...</p>
              <RxReload class="text-cfPrimaryCardHover " />
            </div>
          </div>,
          container
        );
      }
    } catch (error) {
      console.error("Error loading SVG:", error);
    }
  };

  const formFieldsChanged = (fields) => {
    if (
      fields.changed?.component?.key !== undefined &&
      fields.changed?.value !== undefined
    ) {
      if (
        fields.changed?.value[0]?.type &&
        imgRegex.test(fields.changed?.value[0].url)
      ) {
        isToggleOn
          ? setFieldsChanged((prevData) => ({
              ...prevData,
              // Add your new key-value pair here
              [fields.changed?.component?.key]: fields.changed?.value[0].url,
            }))
          : setCustomFieldChanged((prevData) => ({
              ...prevData,
              // Add your new key-value pair here
              [fields.changed?.component?.key]: fields.changed?.value[0].url,
            }));
      } else {
        isToggleOn
          ? setFieldsChanged((prevData) => ({
              ...prevData,
              // Add your new key-value pair here
              [fields.changed?.component?.key]: fields.changed?.value,
            }))
          : setCustomFieldChanged((prevData) => ({
              ...prevData,
              // Add your new key-value pair here
              [fields.changed?.component?.key]: fields.changed?.value,
            }));
      }
    } else if (fields?.data) {
      setFieldsChanged(fields.data);
    }
  };

  const toggleUploadMethod = (bool) => {
    setIsUploadMethod(bool);
  };

  useEffect(() => {
    if (currentSVG) {
      if (fieldsChanged) {
        const updatedFields = isToggleOn
          ? { ...fieldsChanged }
          : { ...customFieldChanged };
        const modifiedSVG = GenerateCertificate(currentSVG, updatedFields); // Use the service function
        document.getElementById("certificate_container").innerHTML =
          modifiedSVG;
      }
    }
  }, [fieldsChanged, customFieldChanged, currentSVG, isToggleOn]);

  useEffect(() => {
    GET_CERTIFICATE_DATA(credential_template_id).then(() => {
      fetchDefaultSVG();
    });
    const previousPath = authContext.lastVisitedPath;
    console.log("previousPath >> ", previousPath);
    if (
      previousPath === "/issuer/drafts" ||
      sessionStorage.getItem("previousPath") === "/issuer/drafts"
    ) {
      setBreadcrumbItems([
        {
          title: "Receiver details",
          to: `/issuer/certificate/1/${credential_template_id}/${issuer_credential_template_id}`,
        },
      ]);
    } else {
      setBreadcrumbItems([
        { title: "Edit", to: `/certificate/0/${credential_template_id}` },
        {
          title: "Receiver details",
          to: `/issuer/certificate/1/${credential_template_id}/${issuer_credential_template_id}`,
        },
      ]);
    }
    authContext.lastVisitedPathHandler(location.pathname);
  }, []);

  return (
    <div className="mt-20">
      {breadcrumbItems.length > 0 && (
        <CfBreadcrumb
          breadcrumbItems={breadcrumbItems}
          isToggleOn={isToggleOn}
          setIsToggleOn={setIsToggleOn}
          marginTop={"mt-[0rem]"}
        />
      )}
      <div className="flex w-full">
        <div className="w-full md:w-[38%] max-h-[82vh] overflow-y-auto flex flex-col items-center flex-grow bg-cfSecondary min-h-full">
          <div className="h-auto w-full">
            <ToggleButton
              First_Text="Single Issuance"
              Second_Text="Batch Issuance"
              showText={true}
              LeftIcon={BsFillFileEarmarkBinaryFill}
              RightIcon={HiSquare3Stack3D}
              onToggle={toggleUploadMethod}
              UploadMethodBool={isUploadMethod}
            />
          </div>
          <div className="p-3 bg-cfSecondary h-full w-full">
            {isUploadMethod
              ? transactionName && (
                  <FileUpload
                    toggleUploadMethod={toggleUploadMethod}
                    transaction_name={transactionName}
                  />
                )
              : transactionName && (
                  <FormioSingleUpload
                    toggleUploadMethod={toggleUploadMethod}
                    URL={certificateData?.credential_subject_form}
                    formFieldsChanged={formFieldsChanged}
                    transaction_name={transactionName}
                    isToggleOn={isToggleOn}
                  />
                )}
          </div>
        </div>

        <div className="w-full mx-1 p-3">
          <div className="w-full">
            <div
              className="flex flex-col flex-shrink  max-h-[78vh]"
              id="certificate_container"
            >
              <CfLoader />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificateIssue;
