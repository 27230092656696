// React
// import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

// Services
import { toastOptions } from "./service/SnackbarService";
import { containerStyle } from "./service/SnackbarService";
// Thrid Party
import { Toaster } from "react-hot-toast";
import Router from "./routes/Router";
// Store
import { AuthContextProvider } from "./store/auth-context";
//Sentry
import * as Sentry from "@sentry/react";

import AppConstants from "./configs/AppConstants";

import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  return (
    <div className="App">
      <GoogleOAuthProvider
        clientId={
          process.env.REACT_APP_GOOGLE_CLIENT_ID || AppConstants.CLIENT_ID
        }
      >
        <AuthContextProvider>
          <Toaster position="bottom-right" reverseOrder={true} />
          <Router />
        </AuthContextProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
