import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import HandleImgError from "../../../utils/HandleURLImgError";
import UserIcon from "../../../resources/icons/User.svg";

const OrganizationProfileBanner = (props) => {
  const {
    certificate_name,
    certificate_description,
    certificate_template_id,
    certificate_tags,
    certificate_thumbnail_svg_url,
    certificate_redirect_url,
    certificate_issuance_count,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const isSingleLine = certificate_name && certificate_name.length <= 20;

  return (
    <div
      className={`group flex flex-col gap-3 bg-cfSecondary border border-cfCardBorder ${
        certificate_redirect_url
          ? "cursor-pointer hover:bg-cfCardHover hover:scale-105"
          : "cursor-default"
      } transition-all duration-500 overflow-hidden w-[26rem] h-[24rem]`}
      certificate_name={certificate_template_id}
      onClick={() => {
        if (certificate_redirect_url) {
          window.open(certificate_redirect_url, "_blank");
        }
      }}
    >
      <div className="flex flex-col ">
        <div className="flex flex-col h-fit">
          <div
            className={`flex items-center h-[15.75rem] border-b w-full bg-cfSecondary ${
              !certificate_thumbnail_svg_url &&
              "w-full animate-pulse bg-zinc-300"
            }`}
          >
            <img
              src={
                (certificate_thumbnail_svg_url ||
                  certificate_thumbnail_svg_url === null) &&
                certificate_thumbnail_svg_url
              }
              onError={(e) => HandleImgError(e, certificate_thumbnail_svg_url)}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        <div className="h-36">
          {/* <div className="flex gap-1 h-8 py-[1.25rem] px-3">
            {certificate_tags &&
              certificate_tags.length > 0 &&
              certificate_tags.slice(0, 2).map((item, index) => (
                <p
                  key={index}
                  className=" text-xs bg-[#EBEBFF] text-[#342F98] hover:text-[#342F98] hover:bg-[#e0e0fc] transition-all duration-300 ease-in-out font-medium w-fit px-2 py-1 h-fit"
                >
                  {item}
                </p>
              ))}
          </div> */}
          <div className="h-10 overflow-ellipsis font-medium text-lg py-[1.25rem] px-3">
            <p
              className={`line-clamp-2 ${
                !certificate_name &&
                "h-8 w-full animate-pulse bg-zinc-300 rounded-md"
              }`}
            >
              {certificate_name}
            </p>
          </div>
          {/* <div
            className={`h-10 overflow-ellipsis font-medium text-md py-[1.25rem] px-3 `}
          >
            <p
              className={`text-xs line-clamp-1 ${
                !certificate_description &&
                "h-8 w-full animate-pulse bg-zinc-300 text-gray-400 rounded-md"
              }`}
            >
              {certificate_description}
            </p>
          </div> */}
          <div
            className={`flex ${isSingleLine ? "mt-[1.9rem]" : "mt-[2.5rem]"}  ${
              certificate_issuance_count === 0 ? "" : "border-t-2"
            }`}
          >
            <div className="flex px-3 py-[0.9rem]">
              {certificate_issuance_count >= 1 && (
                <div>
                  <img src={UserIcon} alt="" />
                </div>
              )}
              <div className="flex gap-1 pl-2">
                {certificate_issuance_count >= 1 && (
                  <div>{certificate_issuance_count}</div>
                )}
                {certificate_issuance_count === 1 && (
                  <div className="text-[#8C94A3]">student</div>
                )}
                {certificate_issuance_count > 1 && (
                  <div className="text-[#8C94A3]">students</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrganizationProfileBanner;
