import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useState } from "react";
import Toast from "../Toast/Toast";
import ReCAPTCHA from "react-google-recaptcha";
import { GiDunceCap } from "react-icons/gi";
import { POST } from "../../service/CRUDService";
import AppConstants from "../../configs/AppConstants";
import EntityConstants from "../../configs/EntityConstants";
import AuthContext from "../../store/auth-context";
import { use } from "i18next";

export default function ForgotPasswordModal({
  isModalOpen,
  certificate_header,
  certificate_description,
}) {
  const InputBorderRadius = "0.5rem";
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [captchaValue, setCaptchaValue] = useState(null);
  const authContext = useContext(AuthContext);

  function closeModal() {
    authContext.forgotPasswordHandler();
    console.log("close modal closed");
  }

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const emailInput = e.target.value.trim();
    setEmail(emailInput);
    setIsEmailValid(validateEmail(emailInput));
  };

  const forgotPassword = async () => {
    if (!isEmailValid) {
      Toast({
        type: "error",
        title: "Login Failed !",
        message: "Please enter a valid email address.",
      });
      return;
    }
    try {
      const response = await POST(EntityConstants.POST_FORGOT_PASSWORD, {
        email,
      });
      const data = await response.json();
      console.log(data);
      if (response.ok) {
        Toast({
          type: "success",
          title: "Success",
          message: data.message,
        });
        console.log(data.message);
        closeModal();
      } else {
        console.log(data.error);
        Toast({
          type: "error",
          title: "Error",
          message: data.error,
        });
      }
    } catch (error) {
      console.error("Failed to send email", error);
      Toast({
        type: "error",
        title: "Error",
        message: "An error occurred while sending the email.",
      });
    }
  };

  return (
    <>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Dialog.Overlay
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                onClick={closeModal}
              />
              <Transition.Child
                as={Fragment}
                enter="transition ease-in duration-200 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-out transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <button
                    type="button"
                    className="absolute top-3 right-3 text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    &#10005;
                  </button>

                  <Dialog.Title
                    as="h3"
                    className="text-4xl pt-3 pb-4 font-medium leading-6 text-gray-900 flex"
                  >
                    {certificate_header}
                  </Dialog.Title>
                  <div className="flex flex-col gap-2 mt-2">
                    <div>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        required
                        placeholder={"Enter your email"}
                        autoComplete="true"
                        className="rounded-md w-full h-12 focus:outline-none border border-cfSignInSignUpInputBorder text-base text-cfSignInSignUpInputPlaceholderText"
                        style={{ borderRadius: InputBorderRadius }}
                        value={email}
                        onChange={handleEmailChange}
                      />
                      {!isEmailValid && (
                        <p className="text-red-500 text-sm mt-1">
                          Please enter a valid email address.
                        </p>
                      )}
                    </div>
                    <div>
                      <p className="text-cfSignInSignUpIntroMessageText py-2 text-base text-gray-500 ml-2">
                        {certificate_description}
                      </p>
                    </div>

                    <div className="flex justify-center my-2">
                      <ReCAPTCHA
                        sitekey={AppConstants.GOOGLE_CAPTCHA_SITE_KEY}
                        onChange={handleCaptchaChange}
                      />
                    </div>
                  </div>

                  <div className="mt-4 flex justify-center">
                    <input
                      type="submit"
                      value={"Submit"}
                      onClick={forgotPassword}
                      disabled={!isEmailValid || !captchaValue}
                      className="rounded-xl text-base font-semibold bg-cfButton disabled:bg-cfButton hover:bg-cfPrimaryDark py-1 px-5  text-cfSecondary w-full h-11"
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
