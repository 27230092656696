import React, { useEffect, useState } from "react";

const AuthContext = React.createContext({
  triggerLoginModuleToggleValue: false,
  setTriggerLoginModuleToggleValue: () => {},
  isLoggedIn: false,
  login: () => {},

  logout: () => {},

  createUserProfile: (id) => {},
  user_profile_id: null,

  createUserProfileDetails: (userDetails) => {},
  userDetails: null,

  createTenantId: (tenant_id) => {},
  tenant_id: null,

  createTenantName: (tenant_name) => {},
  tenant_name: null,
  isSignUpHandler: (isSignUp) => {},
  isSignUp: null,

  isGoogleLogin: false,
  googlelogin: () => {},

  googlelogout: () => {},

  isMicrosoftLogin: false,
  microsoftlogin: () => {},
  microsoftlogout: () => {},

  hasDiscoveryReachedBottomHandler: (bool) => {},
  hasDiscoveryReachedBottom: false,

  lastVisitedPathHandler: () => {},
  lastVisitedPath: null,

  IssuedOffsetHandler: (offset) => {},
  issuedOffset: null,

  forgotPasswordHandler: () => {},
  isForgotPassword: false,
});

export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user_profile_id, setUser_profile_id] = useState(null);
  const [tenant_id, setTenant_id] = useState(null);
  const [tenant_name, setTenant_Name] = useState(null);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const [isMicrosoftLogin, setIsMicrosoftLogin] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [hasDiscoveryReachedBottom, setHasDiscoveryReachedBottom] =
    useState(false);
  const [lastVisitedPath, setLastVisitedPath] = useState(null);
  const [issuedOffset, setIssuedOffset] = useState(
    Number(localStorage.getItem("transactionOffset")) || null
  );
  const [triggerLoginModuleToggleValue, setTriggerLoginModuleToggleValue] =
    useState(false);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);

  //useEffects:

  useEffect(() => {
    lastVisitedPath && sessionStorage.setItem("previousPath", lastVisitedPath);
  }, [lastVisitedPath]);

  useEffect(() => {
    !localStorage.getItem("auth_token") && resetUserDetails();
  }, [localStorage.getItem("auth_token")]);

  useEffect(() => {
    console.log("isForgotPasswordOpen>>>>", isForgotPasswordOpen);
  }, [isForgotPasswordOpen]);

  useEffect(() => {
    console.log(
      "triggerLoginModuleToggleValue>>",
      triggerLoginModuleToggleValue
    );
  }, [triggerLoginModuleToggleValue]);

  const resetUserDetails = () => {
    setIsLoggedIn(false);
    setUser_profile_id(null);
    setTenant_id(null);
    setTenant_Name(null);
    setIsGoogleLogin(false);
    setIsMicrosoftLogin(false);
    setUserDetails({});
  };
  const createUserProfile = (id) => {
    // console.log(id,"id");
    setUser_profile_id(id);
  };
  const createUserProfileDetails = (userDetails) => {
    // console.log(id,"id");
    if (typeof userDetails === "object") {
      setUserDetails(userDetails);
    } else {
      setUserDetails({});
      throw new Error(
        '"object" / "{}" expected to create user profile details'
      );
    }
  };

  const createTenantId = (tenant_id) => {
    setTenant_id(tenant_id);
  };

  const createTenantName = (tenant_name) => {
    setTenant_Name(tenant_name);
  };
  const isSignUpHandler = (bool) => {
    setIsSignUp(bool);
  };

  const loginHandler = () => {
    setIsLoggedIn(true);
  };

  const logoutHandler = () => {
    setIsLoggedIn(false);
  };
  const googleloginHandler = () => {
    setIsGoogleLogin(true);
  };
  const googlelogoutHandler = () => {
    setIsGoogleLogin(false);
  };

  const microsoftLoginHandler = () => {
    setIsMicrosoftLogin(true);
  };

  const microsoftLogoutHandler = () => {
    setIsMicrosoftLogin(false);
  };

  const hasDiscoveryReachedBottomHandler = (bool) => {
    setHasDiscoveryReachedBottom(bool);
  };

  const lastVisitedPathHandler = (lastVisitedPath_) => {
    setLastVisitedPath(lastVisitedPath_);
  };

  const IssuedOffsetHandler = (offset) => {
    setIssuedOffset(offset);
    localStorage.setItem("transactionOffset", offset);
  };
  const contextValue = {
    triggerLoginModuleToggleValue: triggerLoginModuleToggleValue,
    setTriggerLoginModuleToggleValue: setTriggerLoginModuleToggleValue,

    isLoggedIn: isLoggedIn,
    login: loginHandler,

    logout: logoutHandler,

    createUserProfile: createUserProfile,
    user_profile_id: user_profile_id,

    createUserProfileDetails: createUserProfileDetails,
    userDetails: userDetails,

    createTenantId: createTenantId,
    tenant_id: tenant_id,

    createTenantName: createTenantName,
    tenant_name: tenant_name,

    isSignUpHandler: isSignUpHandler,
    isSignUp: isSignUp,

    googlelogin: googleloginHandler,

    googlelogout: googlelogoutHandler,

    hasDiscoveryReachedBottomHandler: hasDiscoveryReachedBottomHandler,
    hasDiscoveryReachedBottom: hasDiscoveryReachedBottom,

    lastVisitedPathHandler: lastVisitedPathHandler,
    lastVisitedPath: lastVisitedPath,

    IssuedOffsetHandler: IssuedOffsetHandler,
    issuedOffset: issuedOffset,

    microsoftLogin: microsoftLoginHandler,
    microsoftLogout: microsoftLogoutHandler,

    isForgotPassword: isForgotPasswordOpen,
    forgotPasswordHandler: () => setIsForgotPasswordOpen(!isForgotPasswordOpen),
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
