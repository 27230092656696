import { Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import EntityConstants from "../../configs/EntityConstants";
import { GET, POST } from "../../service/CRUDService";
import { emailRegex, phoneNoRegex } from "../../configs/App.Regex";
import Toast from "../Toast/Toast";
import { TbSend } from "react-icons/tb";
import { IoCloseCircleOutline } from "react-icons/io5";
import WhatsappIcon from "../../resources/icons/icons8-whatsapp-48.svg";
import EmailIcon from "../../resources/icons/icons8-email-48.svg";
import CfLoader from "../CfLoader/CfLoader";

const NotificationModal = ({
  ImageUrl,
  Recipient_name,
  TriggerBtn,
  TriggerBtnToggle,
  Credential_id,
}) => {
  const [whatsappNo, setWhatsappNo] = useState("");
  const [emailArray, setEmailArray] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [whatsappError, setWhatsappError] = useState("");
  const [isImageLoading, setIsImageLoading] = useState(true);

  const [maxEmailArrLength, setMaxEmailArrLength] = useState(5);

  const [isEmailInputDisabled, setIsEmailInputDisabled] = useState(false);
  const handleSendNotificationEmail = (credential_id, emailArray) => {
    const body = {
      notification_type: "email",
      credential_id: credential_id,
      target: emailArray,
    };
    POST(`${EntityConstants.SEND_NOTIFICATION}`, body)
      .then((resp) => resp.json())
      .then((data) => {
        if (data.error) {
          return Toast({
            type: "error",
            title: "Error",
            message: data.message,
          });
        } else {
          return Toast({
            type: "success",
            title: "Success",
            message: data.message,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSendNotificationWhatsapp = (credential_id, whatsapp_no) => {
    const body = {
      notification_type: "whatsapp",
      credential_id: credential_id,
      target: whatsapp_no,
    };
    POST(`${EntityConstants.SEND_NOTIFICATION}`, body)
      .then((resp) => resp.json())
      .then((data) => {
        if (data.error) {
          return Toast({
            type: "error",
            title: "Error",
            message: data.message,
          });
        } else {
          return Toast({
            type: "success",
            title: "Success",
            message: data.message,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleAddEmail = () => {
    if (!emailError) {
      // setEmailArray([...emailArray, currentEmail])
      setEmailArray((prev) => [...prev, currentEmail]);
      setCurrentEmail("");
    }
  };
  const validateWhatsAppNo = (val) => {
    if (phoneNoRegex.test(val)) {
      setWhatsappNo(val);
      setWhatsappError("");
    } else if (!val) {
      setWhatsappNo("");
    } else {
      setWhatsappError("Please Enter Valid Phone Number");
    }
  };

  const handleSendNotification = (credential_id, whatsapp_no, emailList) => {
    whatsapp_no
      ? handleSendNotificationWhatsapp(credential_id, whatsapp_no)
      : setEmailError("Add a whatsapp number to send notification");
    emailList.length > 0
      ? handleSendNotificationEmail(credential_id, emailList)
      : setEmailError("Add emails to send notification");
  };

  const getDefaultEmailWhatsapp = (Credential_id) => {
    GET(`${EntityConstants.GET_DEFAULT_EMAIL_WHATSAPP}${Credential_id}`)
      .then((resp) => resp.json())
      .then((data) => {
        data.whatsapp_no && setWhatsappNo(data.whatsapp_no);
        data.email && setEmailArray(data.email);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    if (emailArray.length > 0) {
      setIsEmailInputDisabled(false);
    }

    if (emailArray.length > maxEmailArrLength - 1) {
      setIsEmailInputDisabled(true);
      return setEmailError(
        `* You can add up to ${maxEmailArrLength} emails to the list`
      );
    } else {
      setIsEmailInputDisabled(false);
      setEmailError("");
    }

    if (currentEmail && emailRegex.test(currentEmail)) {
      if (emailArray.includes(currentEmail)) {
        return setEmailError("* Email Already Exists");
      }
      return setEmailError("");
    } else if (!currentEmail) {
      return setEmailError("You can add more emails to the list");
    } else {
      return setEmailError("* Please Enter Valid Email");
    }
  }, [currentEmail, emailArray, maxEmailArrLength]);

  useEffect(() => {
    Credential_id && getDefaultEmailWhatsapp(Credential_id);
  }, [Credential_id]);


  return (
    <Transition
      appear
      show={Credential_id === TriggerBtn}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="relative z-10"
      as="div"
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-25 cursor-pointer "
        onClick={() => TriggerBtnToggle(null)}
      />

      <div className="fixed inset-0 overflow-y-auto w-full lg:w-10/12 xl:w-8/12 p-3 md:p-0  mx-auto my-auto h-fit">
        <div className="flex min-h-full items-center justify-center py-2 text-center h-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex flex-col sm:flex-row transform rounded-2xl bg-cfSecondary text-left align-shadow-xl transition-all w-full min-h-[30rem]">
              <div className="bg-cover bg-[#3538cd]/90 p-4 w-full sm:w-1/2 stretch rounded-t-2xl sm:rounded-l-2xl sm:rounded-tr-none  flex flex-col justify-center items-center">
                <div className="rounded-md w-full h-fit drop-shadow-lg flex flex-col justify-center items-center">
                  <img
                    src={`${ImageUrl}`}
                    alt=""
                    onLoad={()=>setIsImageLoading(false)}
                    className={` ${isImageLoading ? "hidden" : "block"} rounded-md w-full my-auto`}
                  />
                  {isImageLoading && <div className="rounded-md bg-white w-full h-full flex justify-center items-center">
                    <div className="rounded-md w-full h-96 bg-black/5 flex justify-center items-center">

                    <CfLoader/>
                    </div>
                    </div>}
                  <div className="flex flex-col gap-2 items-center p-2 text-white">
                    <h1 className="font-semibold text-lg capitalize">
                      {Recipient_name}
                    </h1>
                    <h2>{Credential_id}</h2>
                  </div>
                </div>
                {/* add bg patterns */}
              </div>
              <div className="w-full sm:w-1/2 p-6">
                <div
                  as="div"
                  className="flex flex-col gap-2 items-baseline justify-between font-medium leading-6 text-gray-900 mb-3"
                >
                  <h1 className="text-2xl text-cfGlobalText">Resend Notification</h1>
                </div>
                <div className="flex flex-col gap-4">
                  {/* <p className="flex items-center">{Credential_id}</p> */}
                  <div className="flex gap-2 items-center">
                    <div className="flex border-[1.5px] border-cfGlobalText rounded-md w-full">
                      <p className="text-cfGlobalText p-1 flex items-center justify-center">
                        <img src={WhatsappIcon} alt="" width={30} />
                      </p>
                      <div className="border-l-[1.5px] border-cfGlobalText w-full">
                        <input
                          type="tel"
                          autoFocus
                          name="whatsapp_no"
                          id="whatsapp_no"
                          placeholder="xxx xxx xxxx"
                          defaultValue={whatsappNo}
                          onChange={(e) => validateWhatsAppNo(e.target.value)}
                          className="border-none focus:outline-none pr-2 rounded-lg placeholder:text-gray-400 w-full"
                        />
                      </div>
                    </div>
                  </div>
                  {whatsappError && whatsappNo.length > 0 && (
                    <p className="text-red-500">{whatsappError}</p>
                  )}
                  <div className="flex gap-2 items-center">
                    <div className="flex border-[1.5px]  border-cfGlobalText rounded-md w-full">
                      <p className="text-cfGlobalText p-1 flex items-center justify-center">
                        <img src={EmailIcon} alt="" width={30} />
                      </p>
                      <div
                        name="emailForm"
                        className="flex items-start gap-2 flex-wrap border-l-[1.5px] border-cfGlobalText p-2 bg-cfSecondary rounded-r-[0.25rem] h-40 overflow-y-auto"
                      >
                        {emailArray.map((email) => (
                          <p className="bg-[#3538cd] hover:bg-[#3538cd]/90 text-cfSecondary px-2 py-1 rounded-full flex gap-1 items-center">
                            {email}
                            <p
                              className="flex items-center justify-center rounded-full text-lg h-6 w-6 cursor-pointer"
                              onClick={() =>
                                setEmailArray(
                                  emailArray.filter((item) => item !== email)
                                )
                              }
                            >
                              <IoCloseCircleOutline size={25} />
                            </p>{" "}
                          </p>
                        ))}
                        {!isEmailInputDisabled && (
                          <div className="w-fit flex gap-2 items-center">
                            {
                              <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="john.doe@gmail.com"
                                disabled={isEmailInputDisabled}
                                value={currentEmail}
                                size={currentEmail.length}
                                style={{
                                  borderWidth: "2px",
                                  borderRadius: "99999px",
                                }}
                                className={`border-2 border-cfPrimaryLight focus:border-cfPrimaryCardHover ${
                                  currentEmail
                                    ? "rounded-full"
                                    : "border-none rounded-lg"
                                } focus:outline-none w-full text-cfGlobalText placeholder:text-gray-400 px-2 py-1`}
                                onChange={(e) =>
                                  setCurrentEmail(e.target.value)
                                }
                                onKeyDown={(e) =>
                                  e.key === "Enter" && handleAddEmail()
                                }
                              />
                            }
                            {!emailError && (
                              <button
                                className="rounded-full text-lg bg-cfPrimaryLight hover:bg-cfPrimaryCardHover text-cfSecondary flex items-center justify-center px-2 py-1"
                                onClick={handleAddEmail}
                              >
                                +Add
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {emailError && emailArray.length > 0 && (
                    <p
                      className={`${
                        emailError.startsWith("*")
                          ? "text-red-500"
                          : "text-[#3538cd]"
                      }`}
                    >
                      {emailError}
                    </p>
                  )}
                </div>
                <div className="flex justify-end py-2">
                  <button
                    className="bg-[#3538cd] hover:bg-cfPrimaryCardHover disabled:bg-cfDisabled text-cfSecondary rounded-lg px-4 py-2 flex gap-2 items-center text-lg font-semibold"
                    disabled={!whatsappNo && !(emailArray.length > 0)}
                    onClick={() =>
                      handleSendNotification(
                        Credential_id,
                        whatsappNo,
                        emailArray
                      )
                    }
                  >
                    <TbSend /> Send
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
};

export default NotificationModal;
