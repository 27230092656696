import Toast from "../common/Toast/Toast";
// import EntityConstants from "../configs/EntityConstants";
import { POST } from "./CRUDService";
import EntityConstants from "../configs/EntityConstants";
import AppConstants from "../configs/AppConstants";

const GLogin = async (response, state) => {
  switch (state) {
    case "success":
      const resp = await POST(`${EntityConstants.G_LOGIN}`, {
        ...response,
        ...AppConstants.TENANT,
        ...AppConstants.ENTITY,
        ...AppConstants.GOOGLE_CLIENT,
      });
      console.log("the respponsebis : ", resp);
      const data = await resp.json();
      if (resp.ok) {
        Toast({
          type: "success",
          title: "Success",
          message: "Sign In Successful",
        });
        localStorage.setItem("auth_token", data.access);
        localStorage.setItem("refresh_token", data.refresh);
        return true;
      } else {
        Toast({
          type: "error",
          title: "Error",
          message: `${data.error}\n${data.message}`,
        });
        return false;
      }
    case "failed":
      Toast({
        type: "error",
        title: "Error",
        message: response,
      });
      return false;
    default:
      console.warn("Please pass a valid state.\nerror with Google login...");
      return false;
  }
};

function generateCodeVerifier() {
  const array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => ("0" + dec.toString(16)).substr(-2)).join(
    ""
  );
}

async function generateCodeChallenge(codeVerifier) {
  const buffer = await crypto.subtle.digest(
    "SHA-256",
    new TextEncoder().encode(codeVerifier)
  );
  const base64Url = btoa(
    String.fromCharCode.apply(null, new Uint8Array(buffer))
  )
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
  return base64Url;
}

const microsoftAuth = async () => {
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = await generateCodeChallenge(codeVerifier);
  sessionStorage.setItem("pkce_code_verifier", codeVerifier);

  const queryParams = new URLSearchParams({
    client_id: AppConstants.MICROSOFT_CLIENT_ID,
    response_type: AppConstants.MICROSOFT_RESPONSE_TYPE,
    response_mode: AppConstants.MICROSOFT_RESPONSE_MODE,
    scope: AppConstants.MICROSOFT_SCOPE,
    state: AppConstants.MICROSOFT_STATE,
    redirect_uri: AppConstants.HTTPS_HOST,
    code_challenge: codeChallenge,
    code_challenge_method: "S256",
  });

  // Redirect the user to the Microsoft login page
  const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${queryParams.toString()}`;
  window.location.href = url; // Perform a redirect
};

const MLogin = async (accessToken) => {
  const resp = await POST(`${EntityConstants.MICROSOFT_LOGIN}`, {
    access_token: accessToken,
    ...AppConstants.TENANT,
    ...AppConstants.ENTITY,
  });
  const data = await resp.json();
  if (resp.ok) {
    Toast({
      type: "success",
      title: "Success",
      message: "Sign In Successful",
    });
    localStorage.setItem("auth_token", data.access);
    localStorage.setItem("refresh_token", data.refresh);
    return true;
  } else {
    Toast({
      type: "error",
      title: "Error",
      message: `${data.error}\n${data.message}`,
    });
    return false;
  }
};
const LinkedinLogin = (credentialResponse) => {
  //sample function for linkedin or any other oauth login
  console.log(credentialResponse.credential);
};
export { GLogin, LinkedinLogin, MLogin, microsoftAuth };
