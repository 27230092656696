import React, { useContext, useEffect, useRef, useState } from "react";
import DraftsCredentialTemplateCard from "../../components/Cards/DraftsCredentialTemplateCard/DraftsCredentialTemplateCard";
import { GET } from "../../service/CRUDService";
import EntityConstants from "../../configs/EntityConstants";
import AuthContext from "../../store/auth-context";

import CfLoader from "../../common/CfLoader/CfLoader";
import { useLocation, useNavigate } from "react-router-dom";
import CalculateCardsPerRow from "../../service/CalculateCardsPerRow";

const Draft = () => {
  const authContext = useContext(AuthContext);
  const location = useLocation();

  const [isLoadingCredTemplates, setIsLoadingCredTemplates] = useState(false);
  const [cardWidth, setCardWidth] = useState("19.125rem");
  const [gap, setGap] = useState("1.5rem");
  const containerRef = useRef(null);
  const [cardsPerRow, setCardsPerRow] = useState(0);
  const [countRecentList, setCountRecentList] = useState(0);
  const [countLast30DaysList, setCountLast30DaysList] = useState(0);
  const [countLast3MonthsList, setCountLast3MonthsList] = useState(0);
  const [countLast6MonthsList, setCountLast6MonthsList] = useState(0);
  const [countOtherList, setCountOtherList] = useState(0);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [recentList, setRecentList] = useState([]);
  const [last30DaysList, setLast30DaysList] = useState([]);
  const [last3MonthsList, setLast3MonthsList] = useState([]);
  const [last6MonthsList, setLast6MonthsList] = useState([]);
  const [otherList, setOtherList] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchCompleted, setSearchCompleted] = useState(false);

  const navigate = useNavigate();

  const SEARCH = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    if (searchTerm.trim() === "") {
      setIsSearching(false);
      setSearchResults([]);
      setSearchCompleted(false);
      return;
    }
    setIsSearching(true);
    setSearchCompleted(false);
    GET(`${EntityConstants.GET_DRAFTS_TEMPLATES}search=${searchTerm}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
          setIsSearching(false);
          setSearchResults([]);
        } else {
          setSearchResults(data.results);
        }
        setSearchCompleted(true);
      })
      .catch((error) => {
        console.error(error);
        setIsSearching(false);
        setSearchResults([]);
        setSearchCompleted(true);
      });
  };

  const GET_ISSUER_RECENT = (limit, offset, startDay, endDay) => {
    setIsLoadingCredTemplates(true);
    GET(
      `${EntityConstants.GET_DRAFTS_TEMPLATES}limit=${limit}&offset=${offset}&start_day=${startDay}&end_day=${endDay}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setNext(data.next);
          setPrevious(data.previous);
          setCountRecentList(data.count);
          setRecentList((perv) => [...perv, ...data.results]);
        }
      })
      .then(() => setIsLoadingCredTemplates(false))
      .catch((error) => {
        console.error(error);
      })
      .then(() => setIsLoadingCredTemplates(false));
  };

  const GET_ISSUER_LAST_30_DAYS = (limit, offset, startDay, endDay) => {
    setIsLoadingCredTemplates(true);
    GET(
      `${EntityConstants.GET_DRAFTS_TEMPLATES}limit=${limit}&offset=${offset}&start_day=${startDay}&end_day=${endDay}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setNext(data.next);
          setPrevious(data.previous);
          setCountLast30DaysList(data.count);
          setLast30DaysList((perv) => [...perv, ...data.results]);
        }
      })
      .then(() => setIsLoadingCredTemplates(false))
      .catch((error) => {
        console.error(error);
      })
      .then(() => setIsLoadingCredTemplates(false));
  };

  const GET_ISSUER_LAST_3_MONTHS = (limit, offset, startDay, endDay) => {
    setIsLoadingCredTemplates(true);
    GET(
      `${EntityConstants.GET_DRAFTS_TEMPLATES}limit=${limit}&offset=${offset}&start_day=${startDay}&end_day=${endDay}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setNext(data.next);
          setPrevious(data.previous);
          setCountLast3MonthsList(data.count);
          setLast3MonthsList((perv) => [...perv, ...data.results]);
        }
      })
      .then(() => setIsLoadingCredTemplates(false))
      .catch((error) => {
        console.error(error);
      })
      .then(() => setIsLoadingCredTemplates(false));
  };

  const GET_ISSUER_LAST_6_MONTHS = (limit, offset, startDay, endDay) => {
    setIsLoadingCredTemplates(true);
    GET(
      `${EntityConstants.GET_DRAFTS_TEMPLATES}limit=${limit}&offset=${offset}&start_day=${startDay}&end_day=${endDay}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setNext(data.next);
          setPrevious(data.previous);
          setCountLast6MonthsList(data.count);
          setLast6MonthsList((perv) => [...perv, ...data.results]);
        }
      })
      .then(() => setIsLoadingCredTemplates(false))
      .catch((error) => {
        console.error(error);
      })
      .then(() => setIsLoadingCredTemplates(false));
  };

  const GET_ISSUER_LAST_OTHERS = (limit, offset, startDay, endDay) => {
    setIsLoadingCredTemplates(true);
    GET(
      `${EntityConstants.GET_DRAFTS_TEMPLATES}limit=${limit}&offset=${offset}&start_day=${startDay}&end_day=${endDay}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setNext(data.next);
          setPrevious(data.previous);
          setCountOtherList(data.count);
          setOtherList((perv) => [...perv, ...data.results]);
        }
      })
      .then(() => setIsLoadingCredTemplates(false))
      .catch((error) => {
        console.error(error);
      })
      .then(() => setIsLoadingCredTemplates(false));
  };

  const removeCredentialFromList = (credentialId) => {
    const removeAndEnsure = (list, setList, startDay, endDay, count) => {
      setList((prev) => {
        const updatedList = prev.filter(
          (item) => item.issuer_credential_template_id !== credentialId
        );
        ensureFullRow(updatedList, setList, startDay, endDay, count);
        return updatedList;
      });
    };
    const ensureFullRow = (list, setList, startDay, endDay, count) => {
      if (list.length < cardsPerRow && count > list.length) {
        const offset = list.length;
        GET(
          `${EntityConstants.GET_DRAFTS_TEMPLATES}limit=${
            cardsPerRow - list.length
          }&offset=${offset}&start_day=${startDay}&end_day=${endDay}`
        )
          .then((response) => response.json())
          .then((data) => {
            if (!data.error && data.results.length > 0) {
              setList((prev) => [...prev, ...data.results]);
            }
          })
          .catch((error) => {
            console.error("Error fetching additional certificates:", error);
          });
      }
    };
    removeAndEnsure(searchResults, setSearchResults);
    removeAndEnsure(recentList, setRecentList, 0, 7, countRecentList);
    removeAndEnsure(
      last30DaysList,
      setLast30DaysList,
      7,
      30,
      countLast30DaysList
    );
    removeAndEnsure(
      last3MonthsList,
      setLast3MonthsList,
      30,
      90,
      countLast3MonthsList
    );
    removeAndEnsure(
      last6MonthsList,
      setLast6MonthsList,
      90,
      180,
      countLast6MonthsList
    );
    removeAndEnsure(otherList, setOtherList, 180, 365, countOtherList);
  };

  useEffect(() => {
    const updateCardsPerRow = () => {
      if (containerRef.current) {
        const calculatedCardsPerRow = CalculateCardsPerRow(
          containerRef,
          cardWidth,
          gap
        );
        console.log("calculatedCardsPerRow >> ", calculatedCardsPerRow);
        if (calculatedCardsPerRow < 5) {
          setCardsPerRow(4);
        } else {
          setCardsPerRow(calculatedCardsPerRow);
        }
      }
    };

    updateCardsPerRow();
    // window.addEventListener('resize', updateCardsPerRow);
    // return () => window.removeEventListener('resize', updateCardsPerRow);
  }, [cardWidth, gap]);

  useEffect(() => {
    if (cardsPerRow > 0) {
      GET_ISSUER_RECENT(cardsPerRow, 0, 0, 7);
      GET_ISSUER_LAST_30_DAYS(cardsPerRow, 0, 7, 30);
      GET_ISSUER_LAST_3_MONTHS(cardsPerRow, 0, 30, 90);
      GET_ISSUER_LAST_6_MONTHS(cardsPerRow, 0, 90, 180);
      GET_ISSUER_LAST_OTHERS(cardsPerRow, 0, 180, 365);
    }
  }, [cardsPerRow]);

  useEffect(() => {
    authContext.lastVisitedPathHandler(location.pathname);
  }, []);
  return (
    <div className="flex flex-col items-center w-full mt-20 bg-cfSecondary min-h-screen">
      <div
        className={`w-full bg-cfSearchContainer flex justify-center py-[0.8rem] px-4`}
      >
        <div
          className={`flex flex-col sm:flex-row items-start sm:items-center justify-between w-full`}
        >
          <div className="flex items-center gap-2 text-cfHeadingText font-semibold text-lg self-start my-auto">
            <p>Draft Templates</p>
          </div>
          {/* Search */}
          <form
            onSubmit={(e) => e.preventDefault()}
            className=" bg-cfSecondary p-2 rounded-lg w-full sm:w-[25rem] self-center ml-[0rem] md:ml-[0.630rem] md:self-end md:m-0"
          >
            <input
              type="text"
              name="search"
              id="search"
              onChange={SEARCH}
              placeholder="Search Drafts"
              className="outline-none border-none p-0 placeholder:text-sm w-full"
              style={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}
            />
          </form>
        </div>
      </div>

      <div ref={containerRef} className="w-full px-4 pb-4">
        {isSearching ? (
          <div className="flex items-center gap-6 flex-wrap w-full mt-8">
            {searchResults.map(
              ({
                issuer_credential_template_id,
                credential_template_id,
                certificate_name,
                preview_image,
                created_at,
                updated_at,
                organisation,
                description,
                tagsList,
              }) => (
                <DraftsCredentialTemplateCard
                  key={issuer_credential_template_id}
                  name={certificate_name}
                  credential_template_id={credential_template_id}
                  issuer_credential_template_id={issuer_credential_template_id}
                  created_at={created_at}
                  lastEdited={updated_at}
                  thumbnail_image={preview_image}
                  description={description}
                  tagsList={tagsList}
                  navigate_url={`/issuer/certificate/0/${credential_template_id}/${issuer_credential_template_id}/edit`}
                  removeCredentialFromList={removeCredentialFromList}
                />
              )
            )}
            {isSearching && !searchCompleted && searchResults.length === 0 && (
              <div className="w-full flex justify-center items-center">
                <CfLoader />
              </div>
            )}
            {searchCompleted && isSearching && searchResults.length === 0 && (
              <div className="mt-6 w-full flex justify-center">
                <p className="text-lg font-semibold text-gray-600">
                  Certificate not found
                </p>
              </div>
            )}
          </div>
        ) : (
          <>
            {recentList[0] && (
              <div className="flex justify-between my-4 flex-wrap">
                <div className="flex items-center gap-2">
                  <p className="text-cfHeadingText font-semibold text-lg">
                    Recent
                  </p>
                  <p className="text-cfTotalCertificates">
                    ({countRecentList})
                  </p>
                </div>
                <div
                  onClick={() =>
                    navigate(
                      `/issuer/drafts/timestamp/${countRecentList}/0/7/recent`
                    )
                  }
                  className="font-cfPrimaryFont text-sm font-semibold text-cfViewAllTagText cursor-pointer"
                >
                  <p>View all</p>
                </div>
              </div>
            )}
            <div className="flex items-center gap-6 flex-wrap w-full">
              {recentList.map(
                ({
                  issuer_credential_template_id,
                  credential_template_id,
                  certificate_name,
                  preview_image,
                  created_at,
                  updated_at,
                  organisation,
                  description,
                  tagsList,
                }) => (
                  <DraftsCredentialTemplateCard
                    key={issuer_credential_template_id}
                    name={certificate_name}
                    credential_template_id={credential_template_id}
                    issuer_credential_template_id={
                      issuer_credential_template_id
                    }
                    created_at={created_at}
                    //! remove this when `updated_at` is added 'lastEdited={created_at?created_at:"14 Jan 2024"}'
                    lastEdited={updated_at}
                    thumbnail_image={preview_image}
                    description={description}
                    tagsList={tagsList}
                    navigate_url={`/issuer/certificate/0/${credential_template_id}/${issuer_credential_template_id}/edit`}
                    removeCredentialFromList={removeCredentialFromList}
                  />
                )
              )}
              {isLoadingCredTemplates && (
                <div className="w-full flex justify-center items-center py-4">
                  <CfLoader />
                </div>
              )}
            </div>
            {last30DaysList[0] && (
              <div className="flex justify-between my-4 flex-wrap">
                <div className="flex items-center gap-2">
                  <p className="text-cfHeadingText font-semibold text-lg">
                    Last 30 Days
                  </p>
                  <p className="text-cfTotalCertificates">
                    ({countLast30DaysList})
                  </p>
                </div>
                <div
                  onClick={() =>
                    navigate(
                      `/issuer/drafts/timestamp/${countLast30DaysList}/7/30/last_30_days`
                    )
                  }
                  className="font-cfPrimaryFont text-sm font-semibold text-cfViewAllTagText cursor-pointer"
                >
                  <p>View all</p>
                </div>
              </div>
            )}
            <div className="flex items-center gap-6 flex-wrap w-full">
              {last30DaysList.map(
                ({
                  issuer_credential_template_id,
                  credential_template_id,
                  certificate_name,
                  preview_image,
                  created_at,
                  updated_at,
                  organisation,
                  description,
                  tagsList,
                }) => (
                  <DraftsCredentialTemplateCard
                    key={issuer_credential_template_id}
                    name={certificate_name}
                    credential_template_id={credential_template_id}
                    issuer_credential_template_id={
                      issuer_credential_template_id
                    }
                    created_at={created_at}
                    //! remove this when `updated_at` is added 'lastEdited={created_at?created_at:"14 Jan 2024"}'
                    lastEdited={updated_at}
                    thumbnail_image={preview_image}
                    navigate_url={`/issuer/certificate/0/${credential_template_id}/${issuer_credential_template_id}/edit`}
                    description={description}
                    tagsList={tagsList}
                    removeCredentialFromList={removeCredentialFromList}
                  />
                )
              )}
            </div>
            {last3MonthsList[0] && (
              <div className="flex justify-between my-4 flex-wrap">
                <div className="flex items-center gap-2">
                  <p className="text-cfHeadingText font-semibold text-lg">
                    Last 3 Months
                  </p>
                  <p className="text-cfTotalCertificates">
                    ({countLast3MonthsList})
                  </p>
                </div>
                <div
                  onClick={() =>
                    navigate(
                      `/issuer/drafts/timestamp/${countLast3MonthsList}/30/90/last_3_months`
                    )
                  }
                  className="font-cfPrimaryFont text-sm font-semibold text-cfViewAllTagText cursor-pointer"
                >
                  <p>View all</p>
                </div>
              </div>
            )}
            <div className="flex items-center gap-6 flex-wrap w-full">
              {last3MonthsList.map(
                ({
                  issuer_credential_template_id,
                  credential_template_id,
                  certificate_name,
                  preview_image,
                  created_at,
                  updated_at,
                  organisation,
                  description,
                  tagsList,
                }) => (
                  <DraftsCredentialTemplateCard
                    key={issuer_credential_template_id}
                    name={certificate_name}
                    credential_template_id={credential_template_id}
                    issuer_credential_template_id={
                      issuer_credential_template_id
                    }
                    created_at={created_at}
                    //! remove this when `updated_at` is added 'lastEdited={created_at?created_at:"14 Jan 2024"}'
                    lastEdited={updated_at}
                    thumbnail_image={preview_image}
                    navigate_url={`/issuer/certificate/0/${credential_template_id}/${issuer_credential_template_id}/edit`}
                    description={description}
                    tagsList={tagsList}
                    removeCredentialFromList={removeCredentialFromList}
                  />
                )
              )}
            </div>
            {last6MonthsList[0] && (
              <div className="flex justify-between my-4 flex-wrap">
                <div className="flex items-center gap-2">
                  <p className="text-cfHeadingText font-semibold text-lg">
                    Last 6 Months
                  </p>
                  <p className="text-cfTotalCertificates">
                    ({countLast6MonthsList})
                  </p>
                </div>
                <div
                  onClick={() =>
                    navigate(
                      `/issuer/drafts/timestamp/${countLast6MonthsList}/90/180/last_6_months`
                    )
                  }
                  className="font-cfPrimaryFont text-sm font-semibold text-cfViewAllTagText cursor-pointer"
                >
                  <p>View all</p>
                </div>
              </div>
            )}
            <div className="flex items-center gap-6 flex-wrap w-full">
              {last6MonthsList.map(
                ({
                  issuer_credential_template_id,
                  credential_template_id,
                  certificate_name,
                  preview_image,
                  created_at,
                  updated_at,
                  organisation,
                  description,
                  tagsList,
                }) => (
                  <DraftsCredentialTemplateCard
                    key={issuer_credential_template_id}
                    name={certificate_name}
                    credential_template_id={credential_template_id}
                    issuer_credential_template_id={
                      issuer_credential_template_id
                    }
                    created_at={created_at}
                    //! remove this when `updated_at` is added 'lastEdited={created_at?created_at:"14 Jan 2024"}'
                    lastEdited={updated_at}
                    thumbnail_image={preview_image}
                    navigate_url={`/issuer/certificate/0/${credential_template_id}/${issuer_credential_template_id}/edit`}
                    description={description}
                    tagsList={tagsList}
                    removeCredentialFromList={removeCredentialFromList}
                  />
                )
              )}
            </div>
            {otherList[0] && (
              <div className="flex justify-between my-4 flex-wrap">
                <div className="flex items-center gap-2">
                  <p className="text-cfHeadingText font-semibold text-lg">
                    Others
                  </p>
                  <p className="text-cfTotalCertificates">({countOtherList})</p>
                </div>
                <div
                  onClick={() =>
                    navigate(
                      `/issuer/drafts/timestamp/${countOtherList}/180/365/others`
                    )
                  }
                  className="font-cfPrimaryFont text-sm font-semibold text-cfViewAllTagText cursor-pointer"
                >
                  <p>View all</p>
                </div>
              </div>
            )}
            <div className="flex items-center gap-6 flex-wrap w-full">
              {otherList.map(
                ({
                  issuer_credential_template_id,
                  credential_template_id,
                  certificate_name,
                  preview_image,
                  created_at,
                  updated_at,
                  organisation,
                  description,
                  tagsList,
                }) => (
                  <DraftsCredentialTemplateCard
                    key={issuer_credential_template_id}
                    name={certificate_name}
                    credential_template_id={credential_template_id}
                    issuer_credential_template_id={
                      issuer_credential_template_id
                    }
                    created_at={created_at}
                    //! remove this when `updated_at` is added 'lastEdited={created_at?created_at:"14 Jan 2024"}'
                    lastEdited={updated_at}
                    thumbnail_image={preview_image}
                    navigate_url={`/issuer/certificate/0/${credential_template_id}/${issuer_credential_template_id}/edit`}
                    description={description}
                    tagsList={tagsList}
                    removeCredentialFromList={removeCredentialFromList}
                  />
                )
              )}
            </div>
            {recentList.length === 0 &&
              last30DaysList.length === 0 &&
              last3MonthsList.length === 0 &&
              last6MonthsList.length === 0 &&
              otherList.length === 0 &&
              !isSearching &&
              !isLoadingCredTemplates && (
                <div className="mt-6 w-full flex justify-center">
                  <p className="text-lg font-semibold text-gray-600">
                    No certificates found
                  </p>
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default Draft;
