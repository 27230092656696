import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { BiShow, BiHide } from "react-icons/bi";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Toast from "../../common/Toast/Toast";
import EntityConstants from "../../configs/EntityConstants";
import { POST } from "../../service/CRUDService";
import AlertIcon from "../../resources/icons/alert-triangle.svg";
import AuthContext from "../../store/auth-context";
import CorrectIcon from "../../resources/icons/correct-live.svg";

const ForgotPasswordAccount = () => {
  const [hideShowpassword, setHideShowpassword] = useState("password");
  const [hideShowConfpassword, setHideShowConfpassword] = useState("password");
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [dataMessage, setDataMessage] = useState("");

  const InputBorderRadius = "0.5rem";

  const { uuid, token } = useParams();
  const authContext = useContext(AuthContext);

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase letter.")
      .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase letter.")
      .matches(/^(?=.*\d)/, "Must contain at least one number.")
      .matches(
        /^(?=.*[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/])/,
        "Must contain at least one special character."
      )
      .min(8, "Password must contain at least 8 characters."),
    confirm_password: Yup.string()
      .required("Re-enter password is required")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });

  const closeModal = () => {
    setInvalidPassword(false);
    setValidPassword(false);
  };

  const updatePassword = async (uuid, token, password) => {
    try {
      const response = await POST(EntityConstants.POST_UPDATE_PASSWORD, {
        uuid,
        token,
        password,
      });
      const data = await response.json();
      if (response.ok) {
        console.log(data.message);
        Toast({
          type: "success",
          title: "Success",
          message: data.message,
        });

        setInvalidPassword(false);
        setValidPassword(true);
        setIsSuccess(true);
        setDataMessage(data.message);
      } else {
        console.log(data.error);
        Toast({
          type: "error",
          title: "Error",
          message: data.error,
        });
        setInvalidPassword(true);
        setValidPassword(false);
        setDataMessage(data.error);
      }
    } catch (error) {
      console.error("Failed to update password", error);
      Toast({
        type: "error",
        title: "Error",
        message: "An error occurred while updating the password.",
      });
    }
  };

  return (
    <div className="h-screen bg-cfSecondary w-full relative">
      <section className="bg-cfSecondary w-full absolute left-0 top-[16%]">
        <div className="container flex flex-col items-center py-12">
          {!validPassword && (
            <div className="border shadow-md shadow-[#A4BCFD] px-20 py-20 rounded-xl">
              <div className="text-center">
                <div className="text-3xl text-black font-semibold">
                  Change Password
                </div>
                <div>Enter your new password</div>
              </div>

              <Formik
                initialValues={{ password: "", confirm_password: "" }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  updatePassword(uuid, token, values.password);
                }}
              >
                {({ values }) => (
                  <Form className="pt-[1rem] w-full max-w-sm">
                    {/* New Password Field */}
                    <label
                      htmlFor="password"
                      className="font-medium text-sm text-cfSignInSignUpInputLabelText"
                    >
                      New Password
                    </label>
                    <div className="flex items-center mb-[0.5rem] rounded-md w-full h-11 pl-2 border">
                      <Field
                        type={hideShowpassword}
                        name="password"
                        id="password"
                        placeholder="New Password"
                        className="focus:outline-none border-none w-full text-base text-cfSignInSignUpInputPlaceholderText"
                        style={{ borderRadius: InputBorderRadius }}
                      />
                      <div
                        className="pl-1 pr-2 cursor-pointer"
                        onClick={() =>
                          setHideShowpassword(
                            hideShowpassword === "password"
                              ? "text"
                              : "password"
                          )
                        }
                      >
                        {hideShowpassword === "password" ? (
                          <BiShow />
                        ) : (
                          <BiHide />
                        )}
                      </div>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-sm mb-2"
                    />

                    {/* Confirm Password Field */}
                    <label
                      htmlFor="confirm_password"
                      className="font-medium text-sm text-cfSignInSignUpInputLabelText"
                    >
                      Re-enter Password
                    </label>
                    <div className="flex items-center rounded-md w-full h-11 pl-2 border">
                      <Field
                        type={hideShowConfpassword}
                        name="confirm_password"
                        id="confirm_password"
                        placeholder="Re-enter password"
                        className="focus:outline-none border-none w-full text-base text-cfSignInSignUpInputPlaceholderText"
                        style={{ borderRadius: InputBorderRadius }}
                      />
                      <div
                        className="pl-1 pr-2 cursor-pointer"
                        onClick={() =>
                          setHideShowConfpassword(
                            hideShowConfpassword === "password"
                              ? "text"
                              : "password"
                          )
                        }
                      >
                        {hideShowConfpassword === "password" ? (
                          <BiShow />
                        ) : (
                          <BiHide />
                        )}
                      </div>
                    </div>
                    <ErrorMessage
                      name="confirm_password"
                      component="div"
                      className="text-red-500 text-sm mb-4"
                    />
                    <div className="flex justify-center items-center mt-6">
                      <div>
                        <button
                          type="submit"
                          disabled={
                            isSuccess ||
                            !values.password ||
                            !values.confirm_password
                          }
                          className="rounded-xl focus:outline-none text-base font-semibold bg-cfButton
                    hover:bg-cfPrimaryDark py-1 px-5 text-cfSecondary w-full
                    h-11"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {invalidPassword && (
            <section className="bg-cfSecondary w-[40rem] top-[92%] relative">
              <div className="container flex flex-col items-center py-4">
                <div className="border px-20 py-10 rounded-xl bg-red-100 relative">
                  <button
                    className="absolute focus:outline-none top-2 right-2 text-red-500 font-bold"
                    onClick={closeModal}
                  >
                    X
                  </button>
                  <div className="text-center flex flex-col justify-center items-center">
                    <div>
                      <img src={AlertIcon} height={50} width={50} alt="" />
                    </div>
                    <div className="text-3xl mt-2 text-[#B42318] font-semibold">
                      {dataMessage}
                    </div>
                    <div className="text-[#F04438] mt-2">
                      To reset your password, return to the login page and
                      select "Reset Password" to send a new email.
                    </div>
                    <div className="mt-2">
                      <button
                        className="flex max-w-xs items-center justify-center text-lg font-semibold text-cfSecondary focus:outline-none bg-cfPrimaryButton py-2 px-4 rounded-xl"
                        onClick={() => {
                          authContext.isSignUpHandler(false);
                          authContext.setTriggerLoginModuleToggleValue(true);
                        }}
                      >
                        <p className="text-base">Sign In</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {validPassword && (
            <section className="bg-cfSecondary w-[40rem] top-[92%] relative">
              <div className="container flex flex-col items-center py-4">
                <div className="border px-20 py-10 rounded-xl bg-green-100 relative">
                  <button
                    className="absolute focus:outline-none top-2 right-2 text-green-500 font-bold"
                    onClick={closeModal}
                  >
                    X
                  </button>
                  <div className="text-center flex flex-col justify-center items-center">
                    <div>
                      <img src={CorrectIcon} height={75} width={75} alt="" />
                    </div>
                    <div className="text-xl mt-2 text-[#1db418] font-semibold">
                      {dataMessage}
                    </div>
                    <div className="mt-2">
                      <button
                        className="flex max-w-xs items-center justify-center text-lg font-semibold text-cfSecondary focus:outline-none bg-cfPrimaryButton py-2 px-4 rounded-xl"
                        onClick={() => {
                          authContext.isSignUpHandler(false);
                          authContext.setTriggerLoginModuleToggleValue(true);
                        }}
                      >
                        <p className="text-base">Sign In</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </section>
    </div>
  );
};

export default ForgotPasswordAccount;
