import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SuccessIcon from "../../resources/icons/success.svg";
import AuthContext from "../../store/auth-context";
import ToolTip from "../../common/ToolTip/ToolTip";

const SuccessEmailIdentity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useContext(AuthContext);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const params = {};
    queryParams.forEach((value, key) => {
      params[key] = value;
    });
    console.log("Query Parameters:", params);
  }, [location]);

  const handleTestEmailClick = () => {
    navigate("/issuer/settings?section=email-address");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 px-4">
      <div className="w-full bg-white max-w-md rounded-lg shadow-lg">
        <div className="flex flex-col items-center space-y-6 p-6">
          <img src={SuccessIcon} height={65} width={65} alt="Success Icon" />
          <h1 className="text-2xl font-bold text-center text-gray-900">
            Email Successfully Verified
          </h1>
          <p className="text-center text-gray-600">
            You have successfully verified an email address. You can now start
            sending email from this address.
          </p>
          {authContext.isLoggedIn ? (
            <button
              onClick={handleTestEmailClick}
              className="w-[50%] px-4 py-2 rounded-md font-medium text-cfSecondary bg-cfPrimaryButton transition-colors duration-200 ease-in-out hover:bg-cfPrimary"
            >
              Test Email
            </button>
          ) : (
            <ToolTip
              component={
                <button
                  disabled
                  className="w-full px-4 py-2 rounded-md font-medium text-cfSecondary bg-cfIssuedScreenTabButtonUnSelectedText"
                >
                  Test Email
                </button>
              }
              message={"Please sign-in to test email"}
              tooltipPosition={"top-[-2.8rem]"}
              bgColor={"#E0781A"}
              tooltipWidth={"max-w-[300px]"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessEmailIdentity;
