import React from "react";
import toast from "react-hot-toast";
import CorrectIcon from "../../resources/icons/success-live.svg";
import WrongIcon from "../../resources/icons/alert-triangle.svg";
import WarningIcon from "../../resources/icons/warning-live.svg";

const Toast = ({ type, title, message, duration = 4000 }) => {
  const backgroundColor =
    type === "success"
      ? "#12B76A"
      : type === "error"
      ? "#7F1D1D"
      : type === "warning"
      ? "#FFC107"
      : "#3538CD";

  return toast.custom((t) => (
    <div
      key={t.id}
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } max-w-md w-full shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 relative`}
      style={{
        backgroundColor,
        zIndex: 1050,
      }}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5">
            {type === "success" ? (
              <img className="h-10 w-10" src={CorrectIcon} alt={type} />
            ) : type === "error" ? (
              <img className="h-10 w-10" src={WrongIcon} alt={type} />
            ) : type === "warning" ? (
              <img className="h-10 w-10" src={WarningIcon} alt={type} />
            ) : (
              <img className="h-10 w-10" src={CorrectIcon} alt={type} />
            )}
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-white">{title}</p>
            <p className="mt-1 text-sm text-white">{message}</p>
          </div>
        </div>
      </div>
      <button
        onClick={() => toast.dismiss(t.id)}
        className="absolute top-1 text-lg font-bold right-2 text-white focus:outline-none opacity-100 transition-opacity duration-200"
      >
        ✕
      </button>
      <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-300">
        <div
          className="h-full bg-white"
          style={{
            animation: `toast-timer ${duration / 1000}s linear forwards`,
          }}
        />
      </div>
    </div>
  ));
};

export default Toast;

export const showToast = (type, title, message, duration) => {
  Toast({ type, title, message, duration });
};
