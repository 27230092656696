import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import EntityConstants from "../../configs/EntityConstants";
import { POST, PUT } from "../../service/CRUDService";
import Toast from "../Toast/Toast";
import warning from "../../images/warning.svg";

export default function DraftsRevokeModal({
  isModalOpen,
  toggleModal,
  removeCredentialFromList,
}) {
  function closeModal(e) {
    if (e) e.stopPropagation();
    toggleModal(null);
  }

  const revokeCredential = async (issuer_credential_template_id) => {
    try {
      const response = await PUT(
        `${EntityConstants.PUT_REVOKE_DRAFT}?issuer_credential_template_id=${issuer_credential_template_id}`
      );
      const data = await response.json();
      if (response.ok) {
        removeCredentialFromList(issuer_credential_template_id);
        Toast({
          type: "success",
          title: "Success",
          message: `${data.details}`,
        });
      } else {
        console.log(data.error);
        Toast({
          type: "error",
          title: "Error",
          message: `${data.error}`,
        });
      }
    } catch (error) {
      console.error("Failed to revoke credential", error);
      Toast({
        type: "error",
        title: "Error",
        message: "An error occurred while revoking the credential.",
      });
    }

    closeModal();
  };

  return (
    <>
      <Transition appear show={isModalOpen ? true : false} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-black/25 backdrop-blur-md"
              onClick={closeModal}
            />
          </Transition.Child>

          <div
            className="fixed inset-0 overflow-y-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative">
                  <button
                    type="button"
                    className="absolute top-3 right-3 text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    &#10005;
                  </button>

                  <Dialog.Title
                    as="h3"
                    className="text-lg flex justify-center font-medium leading-6 text-gray-900"
                  >
                    <img src={warning} alt="warning" />
                  </Dialog.Title>
                  <div className="mt-2 ">
                    <p className="text-lg font-medium flex justify-center">
                      Revoke
                    </p>
                    <p className="text-sm text-gray-500 mt-2 flex justify-center">
                      Are you sure, you want to revoke this draft ?
                    </p>
                  </div>

                  <div className="mt-4 flex justify-between">
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={closeModal}
                      class="py-2.5 px-5 ms-3 text-sm hover:bg-red-800 font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200  focus:z-10 focus:ring-3 focus:ring-gray-100 dark:focus:ring-gray-500 dark:bg-gray-800 dark:text-gray-500 dark:border-gray-600 dark:hover:text-gray-400 dark:hover:bg-gray-700"
                    >
                      Cancel
                    </button>
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={() => revokeCredential(isModalOpen)}
                      class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                    >
                      Revoke
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
