import React, { useContext, useEffect, useState } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import Template from "../../resources/icons/brush.svg";
import Issued from "../../resources/icons/security-safe.svg";
import Draft from "../../resources/icons/folder-favorite.svg";
import Settings from "../../resources/icons/setting-4.svg";
import IssuedDisabled from "../../resources/icons/security-safeDisabled.svg";
import DraftDisabled from "../../resources/icons/folder-favoriteDisabled.svg";
import SettingsDisabled from "../../resources/icons/setting-4Disabled.svg";
import HomeIcon from "../../resources/icons/home-icon.svg";

const Sidebar = () => {
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [home, setHome] = useState("/");
  const [isWindowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    if (authContext.isLoggedIn) {
      setHome("/issuer/discover");
    }
  }, [authContext.isLoggedIn]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isOrganizationPage = location.pathname.startsWith("/organizations");
  const isPasswordResetPage = location.pathname.startsWith(
    "/users/update-password"
  );
  if ((isOrganizationPage || isPasswordResetPage) && !authContext.isLoggedIn) {
    return null;
  }

  const handleIssuedClick = (e) => {
    e.preventDefault();
    authContext.IssuedOffsetHandler(0);
    localStorage.setItem("transactionOffset", 0); // Reset the offset to 0
    navigate("/issuer/transaction/all");
  };

  const public_navs = [
    authContext.isLoggedIn
      ? {
          name: "Dashboard",
          to: "/",
          match: {
            type: "exact",
            path: "/",
          },
          icon: HomeIcon,
        }
      : {
          name: "Templates",
          to: "/discovery",
          match: {
            type: "exact",
            path: "/discovery",
          },
          icon: Template,
        },
  ];
  const issuer_navs = [
    {
      name: "Templates",
      to: "/discovery",
      match: {
        type: "exact",
        path: "/discovery",
      },
      icon: Template,
    },
    {
      name: "Drafts",
      to: "/issuer/drafts",
      match: {
        type: "partial",
        path: "/issuer/drafts",
      },
      icon: Draft,
      disabledIcons: DraftDisabled,
    },
    {
      name: "Issued",
      to: "/issuer/transaction/all",
      match: {
        type: "partial",
        path: "/issuer/transaction/",
      },
      icon: Issued,
      disabledIcons: IssuedDisabled,
      onClick: handleIssuedClick,
    },
    {
      name: "Settings",
      to: "/issuer/settings",
      match: {
        type: "partial",
        path: "/issuer/settings",
      },
      icon: Settings,
      disabledIcons: SettingsDisabled,
    },
  ];

  return (
    <div
      className="flex sm:h-full h-[4rem] bg-cfSecondary items-center sm:items-start py-3 
      sm:w-[5.688rem] w-full border-t-[1.5px] sm:border-t-0 sm:border-r-[1.5px] fixed sm:relative bottom-0 overflow-x-clip overflow-y-clip"
    >
      <div className="flex w-full">
        <div className="flex flex-row sm:!flex-col h-full w-full font-CfSecondaryFont gap-y-4 gap-x-0 justify-start sm:justify-start">
          {public_navs.map(({ name, to, match, icon }) => (
            <NavLink
              key={name}
              to={to}
              title={name}
              className={`flex flex-grow justify-center w-[20%] sm:w-full font-CfPrimaryFont text-sm capitalize py-2 bg-opacity-40 hover:text-cfPrimaryLight hover:no-underline`}
            >
              <div
                className={`h-1 sm:h-14 w-[20%] sm:w-1 absolute bottom-0 sm:bottom-auto sm:left-0 transition-all duration-50 ease-in-out ${
                  ((match.type === "exact" &&
                    location.pathname === match.path) ||
                    (match.type === "partial" &&
                      location.pathname.startsWith(match.path))) &&
                  "bg-cfPrimaryCardHover"
                }`}
              />
              <div
                className={`w-full flex flex-col items-center gap-1 sm:gap-1 justify-center ${
                  ((match.type === "exact" &&
                    location.pathname === match.path) ||
                    (match.type === "partial" &&
                      location.pathname.startsWith(match.path))) &&
                  "font-semibold"
                }`}
              >
                <img src={icon} alt="" className="w-7 h-8 sm:w-6" />
                <div
                  className="text-xs sm:text-sm overflow-hidden text-ellipsis"
                  style={{ maxWidth: "90%" }}
                >
                  {name}
                </div>
              </div>
            </NavLink>
          ))}
          {authContext.isLoggedIn &&
            issuer_navs.map(({ name, to, match, icon, onClick }, index) => (
              <>
                {!(
                  index ===
                  issuer_navs.length - (isWindowSize >= 640 ? 1 : 0)
                ) && (
                  <NavLink
                    key={name}
                    to={to}
                    title={name}
                    className={`flex flex-grow justify-center w-[20%] sm:w-full font-CfPrimaryFont text-sm capitalize py-2 bg-opacity-40 hover:text-cfPrimaryLight hover:no-underline`}
                    onClick={onClick}
                  >
                    <div
                      className={`h-1 sm:h-14 w-[20%] sm:w-1 absolute bottom-0 sm:bottom-auto sm:left-0 transition-all duration-50 ease-in-out ${
                        ((match.type === "exact" &&
                          location.pathname === match.path) ||
                          (match.type === "partial" &&
                            location.pathname.startsWith(match.path))) &&
                        "bg-cfPrimaryCardHover"
                      }`}
                    />
                    <div
                      className={`w-full flex flex-col items-center gap-1 justify-center ${
                        ((match.type === "exact" &&
                          location.pathname === match.path) ||
                          (match.type === "partial" &&
                            location.pathname.startsWith(match.path))) &&
                        "font-semibold"
                      }`}
                    >
                      <img src={icon} alt="" className="w-7 h-8 sm:w-6" />
                      <div
                        className="text-xs sm:text-sm overflow-hidden text-ellipsis"
                        style={{ maxWidth: "90%" }}
                      >
                        {name}
                      </div>
                    </div>
                  </NavLink>
                )}
              </>
            ))}
        </div>
      </div>
      {authContext.isLoggedIn && (
        <div className="absolute hidden sm:block sm:left-0 sm:bottom-20 bottom-0 w-full">
          <NavLink
            to={issuer_navs[issuer_navs.length - 1].to}
            title={issuer_navs[issuer_navs.length - 1].name}
            className={`flex flex-grow justify-center font-CfPrimaryFont text-sm capitalize bg-opacity-40 pb-1 hover:text-cfPrimaryLight hover:no-underline`}
          >
            <div
              className={`h-1 sm:h-14 w-20 sm:w-1 absolute bottom-0 sm:bottom-auto sm:left-0 ${
                ((issuer_navs[issuer_navs.length - 1].match.type === "exact" &&
                  location.pathname ===
                    issuer_navs[issuer_navs.length - 1].match.path) ||
                  (issuer_navs[issuer_navs.length - 1].match.type ===
                    "partial" &&
                    location.pathname.startsWith(
                      issuer_navs[issuer_navs.length - 1].match.path
                    ))) &&
                "bg-cfPrimaryCardHover"
              }`}
            />
            <div
              className={`w-full flex flex-col items-center gap-1 justify-center ${
                ((issuer_navs[issuer_navs.length - 1].match.type === "exact" &&
                  location.pathname ===
                    issuer_navs[issuer_navs.length - 1].match.path) ||
                  (issuer_navs[issuer_navs.length - 1].match.type ===
                    "partial" &&
                    location.pathname.startsWith(
                      issuer_navs[issuer_navs.length - 1].match.path
                    ))) &&
                "font-semibold"
              }`}
            >
              <img
                src={issuer_navs[issuer_navs.length - 1].icon}
                alt=""
                className="w-6"
              />
              {issuer_navs[issuer_navs.length - 1].name}
            </div>
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
