import React from "react";

export const BtnSave = ({
  Display_Icon,
  TriggerBtn,
  DisplayText,
  formData,
}) => {
  return (
    <div className="flex items-center justify-start xl:justify-start w-full">
      <button
        className={`relative inline-flex items-center justify-start px-6 md:px-12 py-2 overflow-hidden font-medium transition-all ${
          formData ? "bg-cfPrimary" : "bg-gray-400"
        } rounded-md group w-fit focus:outline-none group-hover:drop-shadow-md`}
        onClick={TriggerBtn}
        disabled={!formData}
      >
        <span
          className={`absolute bottom-0 left-0 w-full h-full ${
            formData ? "bg-cfPrimaryDark" : "bg-gray-400"
          } rounded-xl`}
        ></span>
        <span className="relative flex items-center w-full text-left text-cfSecondary transition-colors duration-200 ease-in-out group-hover:text-cfSecondary">
          {DisplayText}
        </span>
      </button>
    </div>
  );
};

export const BtnVerifyEmailSave = ({
  Display_Icon,
  TriggerBtn,
  DisplayText,
  enabledButton,
  disabledButton,
}) => {
  return (
    <div className="flex items-center justify-start xl:justify-start w-full">
      <button
        className={`relative inline-flex items-center justify-start px-6 md:px-12 py-2 overflow-hidden font-medium transition-all ${
          enabledButton ? "bg-cfPrimary" : "bg-gray-400"
        } rounded-md group w-fit focus:outline-none group-hover:drop-shadow-md`}
        onClick={TriggerBtn}
        disabled={disabledButton}
      >
        <span
          className={`absolute bottom-0 left-0 w-full h-full ${
            enabledButton ? "bg-cfPrimaryDark" : "bg-gray-400"
          } rounded-xl`}
        ></span>
        <span className="relative flex items-center w-full text-left text-cfSecondary transition-colors duration-200 ease-in-out group-hover:text-cfSecondary">
          {DisplayText}
        </span>
      </button>
    </div>
  );
};
