import React, { useContext, useEffect, useState } from "react";
import ProfilePicture from "../../../resources/icons/profile-circle-disabled.svg";
import AuthContext from "../../../store/auth-context";
import { POST } from "../../../service/CRUDService";
import EntityConstants from "../../../configs/EntityConstants";
import Toast from "../../../common/Toast/Toast";
import EmailIcon from "../../../resources/icons/icons8-email-48_purple.svg";

const Profile = () => {
  const [isFieldChanged, setIsFieldChanged] = useState(false);

  const authContext = useContext(AuthContext);
  const [formData, setFormData] = useState({
    first_name: authContext.userDetails.first_name,
    last_name: authContext.userDetails.last_name,
    gender: authContext.userDetails.gender || "Male", // Set default gender if not available
    email: authContext.userDetails.email,
    date_of_birth: authContext.userDetails.date_of_birth,
    phone: authContext.userDetails.phone,
    profile_photo: null, // To store the uploaded photo in Base64 format
  });

  // State for profile image preview
  const [previewSrc, setPreviewSrc] = useState(
    authContext.userDetails.profile_photo || ProfilePicture
  );

  // Function to handle both form inputs and file uploads
  const handleChange = (event) => {
    setIsFieldChanged(true);
    console.log("first");
    const { name, value, type, files } = event.target;

    if (type === "file") {
      const file = files[0];

      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file); // Read the file as Data URL (Base64)

        reader.onloadend = () => {
          const base64String = reader.result;
          setFormData((prevData) => ({
            ...prevData,
            [name]: base64String, // Store the Base64 string in formData
          }));
          setPreviewSrc(base64String); // Update preview image source
        };
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Function to handle saving the profile with the uploaded photo and changed data
  const handleSave = () => {
    const payload = {};

    // Append all form data to the payload, including the Base64 image
    Object.entries(formData).forEach(([key, value]) => {
      if (
        value !== null &&
        value !== "" &&
        !(key === "profile_photo" && formData.profile_photo === null)
      ) {
        payload[key] = value;
      }
    });

    // Send API request to update user details
    POST(`${EntityConstants.CURRENT_USER}update`, payload)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        window.location.reload();
      })
      .catch((error) => console.log("Error:", error));
  };

  // ProfilePicUpload Component
  function ProfilePicUpload({
    id,
    altText,
    onFileUpload,
    logoHeading,
    logoSubHeading,
  }) {
    const [dragActive, setDragActive] = useState(false);

    const handleFileChange = (event) => {
      setIsFieldChanged(true);
      const file = event.target.files[0];
      processFile(file);
    };

    const handleDragOver = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setDragActive(true);
    };

    const handleDragLeave = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setDragActive(false);
    };

    const handleDrop = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setDragActive(false);

      const file = event.dataTransfer.files[0];
      processFile(file);
    };

    const processFile = (file) => {
      const allowedFileTypes = ["svg", "png", "jpeg", "jpg"];
      console.log("The file is : ", file);

      if (file) {
        const extension = file.name.split(".").pop().toLowerCase();
        const isValid = allowedFileTypes.includes(extension);

        if (isValid) {
          onFileUpload(file);
          console.log("the output is : ", isValid);
        } else {
          console.log("Give a proper format", isValid);
          Toast({
            type: "error",
            title: "Error",
            message: "Please select a valid image format (SVG, PNG, JPEG, JPG)",
          });
        }
      } else {
        console.log("File not found");
        Toast({
          type: "error",
          title: "Error",
          message: "Please select an image",
        });
      }
    };

    useEffect(() => {
      console.log("changed>> ", isFieldChanged);
    }, [isFieldChanged]);

    return (
      <div
        className={`flex gap-4 py-6 px-4 mt-2 cursor-pointer text-gray-500 bg-white rounded-lg outline-dotted outline-gray-300 outline-offset-2 shadow-sm ${
          dragActive ? "bg-gray-100" : ""
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => document.getElementById(id).click()}
      >
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="hidden"
          id={id}
          loading="lazy"
        />
        <label className="cursor-pointer flex flex-row">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6c376ce7ba432a6c2a0781570893c28440a280d4825eeedee07208e78e63d79?apiKey=1ca7de1f58f745cd95efc0640d2ef737&"
            alt={altText}
            className="shrink-0 my-auto w-9 mr-4 aspect-square"
          />
          <div className="flex flex-col">
            <div className="text-sm">
              <span className="font-semibold text-indigo-700">
                {logoHeading} {logoSubHeading}
              </span>
            </div>
            <div className="mt-2 text-xs">SVG, PNG, JPEG (max 800x900px)</div>
          </div>
        </label>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center w-full mt-12 md:mt-20 py-12 px-5 bg-cfSecondary min-h-screen">
      <div className="text-xl font-semibold text-gray-900 w-full mb-4 flex justify-between items-center">
        <h1>Profile</h1>
        <button
          disabled={!isFieldChanged}
          onClick={handleSave} // Trigger save on click
          title={isFieldChanged ? "Save" : "Make changes to Save"}
          className="px-4 py-2 rounded-lg bg-indigo-700 text-base text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          Save
        </button>
      </div>
      <div className="border rounded-xl w-full relative">
        <div className="px-4 pb-4">
          <div className="flex flex-col md:flex-row gap-2 md:gap-0 items-center mb-2 md:mb-4 justify-between py-4">
            <div className="flex items-center gap-8 justify-center">
              {/* Profile Icon */}
              <img
                src={previewSrc}
                className="w-24 h-24 rounded-full"
                alt="Profile"
              />
            </div>
            <ProfilePicUpload
              id="fileInput"
              altText="Profile Upload"
              logoHeading={
                formData.profile_photo
                  ? "Change Uploaded Profile Photo"
                  : "Upload Profile Photo"
              }
              logoSubHeading=""
              onFileUpload={(file) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                  const base64String = reader.result;
                  setFormData((prevData) => ({
                    ...prevData,
                    profile_photo: base64String,
                  }));
                  setPreviewSrc(base64String); // Update preview image source
                };
              }}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <span className="max-w-lg">
              <label
                htmlFor="first_name"
                className="text-sm font-semibold text-black"
              >
                First Name
              </label>
              <input
                type="text"
                placeholder="First Name"
                className="border rounded-lg p-2 w-full text-sm placeholder:text-sm"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange} // Handle input change
              />
            </span>

            {/* Last Name Field */}
            <span className="max-w-lg">
              <label
                htmlFor="last_name"
                className="text-sm font-semibold text-black"
              >
                Last Name
              </label>
              <input
                type="text"
                placeholder="Last Name"
                className="border rounded-lg p-2 w-full text-sm placeholder:text-sm"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange} // Handle input change
              />
            </span>
            <span className="max-w-lg">
              <label
                htmlFor="gender"
                className="text-sm font-semibold text-black"
              >
                Gender
              </label>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange} // Handle dropdown change
                className="border rounded-lg p-2 w-full text-sm placeholder:text-sm"
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </select>
            </span>
            {/* <span className="max-w-lg">
              <label htmlFor="email" className="text-sm font-semibold text-black">
                Email
              </label>
              <input
                type="text"
                placeholder="Email Address"
                className="border rounded-lg p-2 w-full text-sm placeholder:text-sm"
                name="email"
                value={formData.email}
                onChange={handleChange} // Handle input change
              />
            </span> */}
            {/* <span className="max-w-lg">
              <label htmlFor="date_of_birth" className="text-sm font-semibold text-black">
                Date of Birth
              </label>
              <input
                type="date"
                className="border rounded-lg p-2 w-full text-sm placeholder:text-sm"
                name="date_of_birth"
                value={formData.date_of_birth}
                onChange={handleChange} // Handle input change
              />
            </span> */}
            <span className="max-w-lg">
              <label
                htmlFor="phone"
                className="text-sm font-semibold text-black"
              >
                Mobile
              </label>
              <input
                type="tel"
                placeholder="+91 9999999999"
                className="border rounded-lg p-2 w-full text-sm placeholder:text-sm"
                name="phone"
                value={formData.phone}
                pattern="^\+\d{1,3} \d{7,15}$"
                onChange={handleChange} // Handle input change
              />
            </span>
          </div>
        </div>
      </div>
      <div className="self-start mt-4">
        <span className="flex items-center gap-2 border rounded-2xl text-sm p-3 ">
          <span className="w-[2rem] sm:w-[5.5rem] h-[2rem] sm:h-[5.5rem] rounded-full bg-[#4338CA] flex items-center justify-center">
            <img
              src={EmailIcon}
              alt=""
              className="w-[2rem] sm:w-[5.5rem] h-[2rem] sm:h-[5.5rem]"
            />
          </span>
          <span className="text-black text-sm">
            <p>
              {authContext.userDetails.first_name +
                " " +
                authContext.userDetails.last_name}
            </p>
            <p>{authContext.userDetails.email}</p>
          </span>
        </span>
      </div>
    </div>
  );
};

export default Profile;
