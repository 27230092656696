import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import FailedIcon from "../../resources/icons/warning-thin.svg";
import AuthContext from "../../store/auth-context";
import ToolTip from "../../common/ToolTip/ToolTip";

const FailedEmailIdentity = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const handleTestEmailClick = () => {
    navigate("/issuer/settings?section=email-address");
  };
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center px-4">
      <div className="flex flex-col items-center max-w-md w-full bg-white shadow-lg rounded-lg p-8 text-center">
        <div className="flex items-center justify-center mb-2">
          <img src={FailedIcon} height={50} width={50} alt="" />
        </div>

        <h1 className="text-2xl font-bold text-gray-900 mb-2">
          Verification Failed
        </h1>
        <p className="text-gray-600 mb-6">
          We're sorry, but we couldn't verify your email address. This could be
          due to an expired or invalid verification link.
        </p>
        {authContext.isLoggedIn ? (
          <button
            onClick={handleTestEmailClick}
            className="w-[50%] px-4 py-2 rounded-md text-cfSecondary font-medium bg-cfPrimaryButton transition-colors duration-200 ease-in-out hover:bg-cfPrimary"
          >
            Retry Verification
          </button>
        ) : (
          <ToolTip
            component={
              <button
                disabled
                className="w-full px-4 py-2 rounded-md text-cfSecondary font-medium bg-cfIssuedScreenTabButtonUnSelectedText"
              >
                Retry Verification
              </button>
            }
            message={"Please sign-in to retry verification"}
            tooltipPosition={"top-[-2.8rem]"}
            bgColor={"#E0781A"}
            tooltipWidth={"max-w-[300px]"}
          />
        )}
        <p className="mt-4 text-sm text-gray-500">
          If you continue to have problems, please contact our support team for
          assistance.
        </p>
      </div>
    </div>
  );
};

export default FailedEmailIdentity;
