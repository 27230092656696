import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router-dom";
import EntityConstants from "../../configs/EntityConstants";
import { GET, POST } from "../../service/CRUDService";
import { imgRegex, whiteSpaceEmptyStrRegex } from "../../configs/App.Regex";
import { Formio } from "@formio/react";
import Toast from "../../common/Toast/Toast";
import CfLoader from "../../common/CfLoader/CfLoader";
import { useNavigate } from "react-router-dom";

import { RxReload } from "react-icons/rx";

import { LuImageOff } from "react-icons/lu";

import GenerateCertificate from "../../service/GenerateCertificate";
import CfBreadcrumb from "../../common/CfBreadcrumb/CfBreadcrumb";

const EditIssuerTemplate = () => {
  const navigate = useNavigate();

  const { credential_template_id, issuer_credential_template_id } = useParams();
  const [certificateTitle, setCertificateTitle] = useState("");
  const [error, setError] = useState("");
  const [fieldsChanged, setFieldsChanged] = useState({});

  const [currentSVG, setCurrentSVG] = useState("");
  const [certificateData, setCertificateData] = useState();
  const [activeTab, setActiveTab] = useState("basic"); // State for active tab
  const [formData, setFormData] = useState({});
  const [certificateRedirectURL, setCertificateRedirectURL] = useState("");
  const [isAuthRequired, setIsAuthRequired] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const certTitle = sessionStorage.getItem("certificate_title");
    const isEmpty = certTitle == null || certTitle === "";
    const isWhiteSpace = whiteSpaceEmptyStrRegex.test(certTitle);

    if (isEmpty || isWhiteSpace) {
      Toast({
        type: "error",
        title: "Error",
        message: "Please fill the required fields.",
      });
      return;
    }

    if (formRef.current) {
      const form = formRef.current;
      setLoading(true);
      try {
        await form.submit();
        console.log("Form submitted successfully!");
      } catch (err) {
        Toast({
          type: "error",
          title: "Error",
          message:
            "Error occurred during submission. Please fill the required fields.",
        });
        console.error("Form submission error:", err);
      } finally {
        setLoading(false);
      }
    }
  };

  const [breadcrumbItems, setBreadcrumbItems] = useState([
    {
      title: "Edit",
      to: `/issuer/certificate/0/${credential_template_id}/${issuer_credential_template_id}/edit`,
    },
  ]);

  // Function to handle tab switching
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const onSubmitHandle = async (sub) => {
    //Submit-> only Preview for Submit form
    let payload = {};

    if (
      sessionStorage.getItem("certificate_title") &&
      sessionStorage.getItem("certificate_title") != "" &&
      !whiteSpaceEmptyStrRegex.test(sessionStorage.getItem("certificate_title"))
    ) {
      payload = {
        form_data: sub,
        credential_template_id: credential_template_id,
        certificate_name: sessionStorage.getItem("certificate_title"),
        issuer_credential_template_id: issuer_credential_template_id,
        certificate_redirect_url:
          sessionStorage.getItem("certificate_redirect_url") || undefined,
        is_auth_required:
          sessionStorage.getItem("is_auth_required") !== null
            ? JSON.parse(sessionStorage.getItem("is_auth_required"))
            : undefined,
        is_public:
          sessionStorage.getItem("is_public") !== null
            ? JSON.parse(sessionStorage.getItem("is_public"))
            : undefined,
      };
      try {
        // Make an API call here
        const response = await POST(
          `${EntityConstants.POST_CREDENTIALS_TEMPLATE_DESIGN}${credential_template_id}/design`,
          payload
        );
        if (response.ok) {
          const data = await response.json();
          sessionStorage.setItem(
            "issuer_credential_template_id",
            data.issuer_credential_template_id
          );
          navigate(
            `/issuer/certificate/1/${data.credential_template_id}/${data.issuer_credential_template_id}`
          );
          Toast({
            type: "success",
            title: "Success",
            message: "Certificate Template Saved Successfully",
          });
        } else {
          const errorData = await response.json();
          if (errorData.message && Array.isArray(errorData.message)) {
            errorData.message.forEach((msg) => {
              Toast({
                type: "error",
                title: "Error",
                message: msg,
              });
            });
          } else {
            Toast({
              type: "error",
              title: "Error",
              message: "API request failed\nPlease retry...",
            });
          }
        }
      } catch (error) {
        console.error("An error occurred:", error);
        Toast({
          type: "error",
          title: "Error",
          message: error,
        });
      }
    }
  };

  const GET_CERTIFICATE_DATA = (issuer_credential_template_id) => {
    GET(
      EntityConstants.GET_DRAFT_CERTIFICATE_DATA + issuer_credential_template_id
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
        } else {
          setCertificateData(data);
          setFormData(data?.default_template_variables_data?.data || {});
        }
      });
  };

  const formFieldsChanged = (fields) => {
    if (fields?.data) {
      setFormData(fields.data); // Store the form data in state
      setFieldsChanged(fields.data);
    }
    if (
      fields.changed?.component?.key !== undefined &&
      fields.changed?.value !== undefined
    ) {
      if (
        fields.changed?.value[0]?.type &&
        imgRegex.test(fields.changed?.value[0].url)
      ) {
        setFieldsChanged((prevData) => ({
          ...prevData,
          // Add your new key-value pair here
          [fields.changed?.component?.key]: fields.changed?.value[0].url,
        }));
      } else {
        setFieldsChanged((prevData) => ({
          ...prevData,
          // Add your new key-value pair here
          [fields.changed?.component?.key]: fields.changed?.value,
        }));
      }
    } else if (fields?.data) {
      setFieldsChanged(fields.data);
    }
  };

  useEffect(() => {
    issuer_credential_template_id &&
      GET_CERTIFICATE_DATA(issuer_credential_template_id);
  }, [issuer_credential_template_id]);

  const fetchSvg = async (certificateData) => {
    const { _1, template_variables_data, template_svg, _2 } = certificateData;
    try {
      const response = await fetch(`${template_svg}`);
      // const response = await fetch(`${template_svg}`);
      if (response.ok) {
        const svgContent = await response.text();
        setCurrentSVG(svgContent);
        setFieldsChanged(template_variables_data);
        //!injecting the svg content into the container
        const container = document.getElementById("certificate_container");
        container.style.filter = "drop-shadow(0 0 2px rgba(0, 0, 0, 0.264))";
        container.innerHTML = svgContent;
      } else if (response.status == 404) {
        const container = document.getElementById("certificate_container");
        ReactDOM.render(
          <div class="text-black flex justify-center w-full h-full text-lg">
            <div className="flex flex-col items-center gap-2 w-fit h-fit">
              <span className="flex items-center gap-2 w-fit h-fit  bg-gray-100 px-2 py-1 rounded-lg">
                <p>Coulden't find image..</p>
                <LuImageOff class="text-cfPrimaryCardHover " />
              </span>
              <span>
                <p>Please create template again...</p>
              </span>
            </div>
          </div>,
          container
        );
      } else {
        const container = document.getElementById("certificate_container");
        ReactDOM.render(
          <div class="text-black flex justify-center w-full h-full text-lg">
            <div
              className="flex items-center gap-2 w-fit h-fit rounded-lg cursor-pointer"
              onClick={() => {
                ReactDOM.render(
                  <span className="w-full flex justify-center">
                    <CfLoader />
                  </span>,
                  container
                );
                certificateData && fetchSvg(certificateData);
              }}
            >
              <p>Couldn't Load Image...</p>
              <RxReload class="text-cfPrimaryCardHover " />
            </div>
          </div>,
          container
        );
      }
    } catch (error) {
      console.error("Error loading SVG:", error);
    }
  };

  useEffect(() => {
    certificateData instanceof Object &&
      certificateData.hasOwnProperty("template_variables_data") &&
      fetchSvg(certificateData);
    certificateData?.certificate_name &&
      setCertificateTitle(certificateData?.certificate_name);
    certificateData?.certificate_redirect_url &&
      setCertificateRedirectURL(certificateData?.certificate_redirect_url);
    certificateData?.is_auth_required &&
      setIsAuthRequired(certificateData?.is_auth_required);
    certificateData?.is_public && setIsPublic(certificateData?.is_public);
  }, [certificateData]);

  useEffect(() => {
    if (currentSVG) {
      const modifiedSVG = GenerateCertificate(currentSVG, fieldsChanged); // Use the service function
      document.getElementById("certificate_container").innerHTML = modifiedSVG;
    }
  }, [fieldsChanged, currentSVG]);

  useEffect(() => {
    if (
      certificateTitle.length === 0 ||
      whiteSpaceEmptyStrRegex.test(certificateTitle)
    ) {
      setError("Certificate Name is required");
      sessionStorage.removeItem("certificate_title");
    } else {
      sessionStorage.setItem("certificate_title", certificateTitle);
      setError("");
    }
  }, [certificateTitle]);

  useEffect(() => {
    if (
      certificateRedirectURL.length === 0 ||
      whiteSpaceEmptyStrRegex.test(certificateRedirectURL)
    ) {
      sessionStorage.removeItem("certificate_redirect_url");
    } else {
      sessionStorage.setItem(
        "certificate_redirect_url",
        certificateRedirectURL
      );
    }
  }, [certificateRedirectURL]);

  useEffect(() => {
    sessionStorage.setItem("is_auth_required", isAuthRequired);
  }, [isAuthRequired]);

  useEffect(() => {
    sessionStorage.setItem("is_public", isPublic);
  }, [isPublic]);

  useEffect(() => {
    const formContainer = document.getElementById("formio");
    if (!formContainer) return;

    formContainer.innerHTML = "";

    if (certificateData?.form_io_url) {
      fetch(certificateData.form_io_url)
        .then((resp) => resp.json())
        .then((formSchema) => {
          Formio.createForm(formContainer, formSchema, {}).then((form) => {
            form.ready.then(() => {
              formRef.current = form;
              form.submission = {
                data: { ...formData },
              };
              form.on("change", (fields) => {
                if (fields?.data) {
                  setFormData(fields.data);
                }
              });
              form.on("submit", (sub) => onSubmitHandle(sub));
            });
          });
        })
        .catch((error) => console.error("Error loading form:", error));
    }
  }, [certificateData?.form_io_url, activeTab]);

  useEffect(() => {
    certificateData?.form_io_url &&
      fetch(certificateData?.form_io_url)
        .then((resp) => resp.json())
        .then((data) => {
          Formio.createForm(document.getElementById("formio"), data, {}).then(
            (form) => {
              formRef.current = form;
              // Changes the language of the i18Next framework.
              // form.language = i18n.language
              form.submission = {
                data: { ...certificateData?.template_variables_data.data },
              };
              form.on("change", formFieldsChanged);
              form.on("submit", (sub) => onSubmitHandle(sub));
            }
          );
          sessionStorage.removeItem("issuer_credential_template_id");
        })
        .catch((error) =>
          console.log("Error while hitting formio url: ", error)
        );
  }, [certificateData?.form_io_url]);

  return (
    <div className="mt-20">
      {breadcrumbItems.length > 0 && (
        <CfBreadcrumb breadcrumbItems={breadcrumbItems} />
      )}
      <div className="flex w-full">
        <div className="flex flex-col w-full md:w-2/5 items-center flex-grow bg-cfSecondary h-screen overflow-y-auto">
          <div className="flex space-x-4 mb-2 mt-4 bg-gray-300 rounded-2xl p-1">
            <button
              className={`px-3 py-1 transition-all duration-200 ${
                activeTab === "basic"
                  ? "bg-white text-black rounded-xl border-none focus:outline-none focus:ring-0"
                  : "bg-gray-300 rounded-lg border-none focus:outline-none focus:ring-0"
              }`}
              onClick={() => handleTabChange("basic")}
            >
              Basic Settings
            </button>
            <button
              className={`px-3 py-1 transition-all duration-200 ${
                activeTab === "advanced"
                  ? "bg-white text-black rounded-xl border-none focus:outline-none focus:ring-0"
                  : "bg-gray-300 rounded-lg border-none focus:outline-none focus:ring-0"
              }`}
              onClick={() => handleTabChange("advanced")}
            >
              Advanced Settings
            </button>
          </div>

          <div className="w-full p-4">
            {activeTab === "basic" ? (
              <div className="flex flex-col w-full items-start">
                <label
                  htmlFor="certificateName"
                  className="after:content-['*'] after:text-red-600"
                >
                  Certificate Name
                </label>
                <div className="flex justify-between items-center focus-within:border-4 focus-within:border-cfCertificateNameInputBorder rounded-xl w-full">
                  <div className="flex justify-between items-center border-[1px] rounded-md p-1 w-full">
                    <input
                      type="text"
                      name="certificateName"
                      className="border-none rounded-md w-full focus:outline-none"
                      id="certificateName"
                      onChange={(e) => setCertificateTitle(e.target.value)}
                      value={certificateTitle}
                    />
                  </div>
                </div>
                {error && <p className="text-red-600">{error}</p>}

                <div id="formio" className="w-full p-2 h-full">
                  <div className="flex justify-center items-center">
                    <CfLoader />
                  </div>
                </div>
              </div>
            ) : (
              <div className="advanced-settings">
                <div className="mb-4">
                  <label
                    htmlFor="certificateRedirectURL"
                    className="text-gray-600"
                  >
                    Certificate Redirect URL
                  </label>
                  <input
                    id="certificateRedirectURL"
                    name="certificateRedirectURL"
                    type="text"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
                    onChange={(e) => setCertificateRedirectURL(e.target.value)}
                    value={certificateRedirectURL}
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="isAuthRequired" className="text-gray-600">
                    Is Authentication Required
                  </label>
                  <select
                    id="isAuthRequired"
                    defaultValue="no"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                    onChange={(e) =>
                      setIsAuthRequired(e.target.value === "yes")
                    }
                    value={isAuthRequired ? "yes" : "no"}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                <div className="mb-4">
                  <label htmlFor="isPublic" className="text-gray-600">
                    Is Public
                  </label>
                  <select
                    id="isPublic"
                    defaultValue="no"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                    onChange={(e) => setIsPublic(e.target.value === "yes")}
                    value={isPublic ? "yes" : "no"}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                <button
                  className="submit-btn mt-6 px-4 py-2 bg-[#3538CD] text-cfSecondary w-full text-white rounded-lg"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="w-full mx-1 p-3">
          <div className="w-full">
            <div
              className="flex flex-col flex-shrink max-h-[78vh]"
              id="certificate_container"
            >
              <CfLoader />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditIssuerTemplate;
