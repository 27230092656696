import React, { useState } from "react";
import ToolTip from "../../common/ToolTip/ToolTip";
import { BtnVerifyEmailSave } from "./SettingButton";
import CorrectIcon from "../../resources/icons/correct-simple.svg";
import WarningIcon from "../../resources/icons/warning-simple.svg";
import AlertIcon from "../../resources/icons/alert-circle.svg";
import { CgAttachment } from "react-icons/cg";
import RefreshIcon from "../../resources/icons/refresh.svg";

const SettingEmailAddress = ({
  isEmail,
  handleEmailChange,
  isEmailValid,
  isEmailSent,
  isVerified,
  handleTestEmail,
  handleVerifyEmail,
  isShowWarning,
  fetchOrgEmailDetails,
  isEmptyEmail,
}) => {
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = () => {
    fetchOrgEmailDetails();
    setIsRefreshing(true);
    setTimeout(() => {
      setIsRefreshing(false);
    }, 2000);
  };

  return (
    <div className="w-full p-3">
      <div className="text-xl font-semibold text-gray-900">
        <p>Add Your Email</p>
      </div>
      <div class="my-4 w-[90%] lg:w-[50%]">
        <div className="flex">
          <label
            class="flex mb-2 text-sm font-medium text-gray-900"
            htmlFor="org_from_mail"
          >
            Email Address
          </label>
        </div>
        <div className="flex gap-4">
          <input
            type="email"
            id="org_from_mail"
            value={isEmail}
            onChange={handleEmailChange}
            className={`bg-gray-50 border ${
              isEmailValid ? "border-gray-300" : "border-red-500"
            } text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
            placeholder="e.g. Ooru@example.com"
            disabled={isVerified}
          />
          {isEmailSent && isVerified && !isEmptyEmail && (
            <ToolTip
              component={
                <img src={CorrectIcon} height={42} width={42} alt="" />
              }
              message={"Email is verified!"}
              tooltipPosition={"top-[-2.8rem]"}
              bgColor={"#12B76A"}
              tooltipWidth={"max-w-[300px]"}
            />
          )}
          {!isVerified && isShowWarning && !isEmptyEmail && (
            <ToolTip
              component={
                <img src={WarningIcon} height={42} width={42} alt="" />
              }
              message={"Email verification failed!"}
              tooltipPosition={"top-[-2.8rem]"}
              bgColor={"#bb2124"}
              tooltipWidth={"max-w-[300px]"}
            />
          )}
          {!isVerified && !isShowWarning && !isEmptyEmail && (
            <ToolTip
              component={
                <img src={WarningIcon} height={42} width={42} alt="" />
              }
              message={"Email verification is pending!"}
              tooltipPosition={"top-[-2.8rem]"}
              bgColor={"#E0781A"}
              tooltipWidth={"max-w-[300px]"}
            />
          )}
        </div>
        {isEmail && !isEmailValid && (
          <div className="text-xs text-red-500 mt-[0.1rem]">
            Please enter a valid email address.
          </div>
        )}
      </div>
      <div className="flex items-center mb-4">
        <img src={AlertIcon} height={25} width={25} alt="" />
        <div className="text-xs text-black">
          {isVerified
            ? `Clicking on “Test Email” will trigger a test email from the above email address to the issuer’s email address.`
            : `Please note that the email address you enter here will be
                      used to send notification emails to the holders`}
        </div>
      </div>
      <div className="flex gap-5">
        <div>
          {isVerified ? (
            <BtnVerifyEmailSave
              Display_Icon={CgAttachment}
              TriggerBtn={handleTestEmail}
              DisplayText={"Test Email"}
              type="submit"
              enabledButton={isEmail && isEmailValid}
              disabledButton={!isEmail && !isEmailValid}
            />
          ) : isEmptyEmail ? (
            <BtnVerifyEmailSave
              Display_Icon={CgAttachment}
              TriggerBtn={handleVerifyEmail}
              DisplayText={"Verify Email"}
              type="submit"
              enabledButton={isEmail && isEmailValid && !isEmailSent}
              disabledButton={!isEmail || !isEmailValid || isEmailSent}
            />
          ) : (
            <BtnVerifyEmailSave
              Display_Icon={CgAttachment}
              TriggerBtn={handleVerifyEmail}
              DisplayText={"Re-send Verification Email"}
              type="submit"
              enabledButton={isEmail && isEmailValid && !isEmailSent}
              disabledButton={!isEmail || !isEmailValid || isEmailSent}
            />
          )}
        </div>

        {!isShowWarning && !isVerified && !isEmptyEmail && (
          <ToolTip
            component={
              <img
                src={RefreshIcon}
                height={30}
                width={30}
                alt="Refresh"
                className={`cursor-pointer transform transition-transform duration-[2000ms] ${
                  isRefreshing ? "rotate-[360deg]" : ""
                }`}
                onClick={handleRefresh}
              />
            }
            message={"Refresh to Update"}
            tooltipPosition={"top-[-2.8rem]"}
            bgColor={"#E0781A"}
            tooltipWidth={"max-w-[300px]"}
          />
        )}
      </div>
    </div>
  );
};

export default SettingEmailAddress;
